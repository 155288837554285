import React, { memo, useEffect, useRef, useState } from "react"
import { Box, Flex } from "@chakra-ui/react"

import ProgressMessage from "./ProgressMessage"
import { COLORS } from "../../../constants/constants"
import { ReactComponent as AirplaneProgress } from "../../../img/airplane_progress_bar.svg"

const MemoizedProgressMessage = memo(ProgressMessage)

const FlightLoadingProgress = ({ finishedFlightUpdate }) => {
  const getStepFromProgress = (p) => {
    if (p < 33.33) return 0
    if (p < 66.66) return 1
    return 2
  }
  const containerRef = useRef(null)
  const [dotCount, setDotCount] = useState(100)
  const [progress, setProgress] = useState(0)
  const [statusStep, setStatusStep] = useState(0)
  const progressIntervalRef = useRef(null)

  useEffect(() => {
    const step = getStepFromProgress(progress)
    setStatusStep((prev) => (step !== prev ? step : prev))
  }, [progress])

  useEffect(() => {
    if (finishedFlightUpdate) {
      setProgress(100)
      clearInterval(progressIntervalRef.current)
    }
  }, [finishedFlightUpdate])

  useEffect(() => {
    setProgress(0)

    progressIntervalRef.current = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 0.1 : 100))
    }, 15)

    return () => {
      clearInterval(progressIntervalRef.current)
    }
  }, [])

  useEffect(() => {
    const updateDotCount = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const dotSizeWithGap = 8
        setDotCount(Math.floor(containerWidth / dotSizeWithGap))
      }
    }

    updateDotCount()
    window.addEventListener("resize", updateDotCount)

    return () => {
      window.removeEventListener("resize", updateDotCount)
    }
  }, [])

  return (
    <Flex width={"100%"} flexDirection={"column"} gap={2}>
      <Flex height={"24px"}>
        <MemoizedProgressMessage step={statusStep} />
      </Flex>

      <Flex alignItems="center" position={"relative"}>
        <Flex ref={containerRef} width={"100%"} gap={1}>
          {Array.from({ length: dotCount }, (_, i) => {
            return (
              <Box
                key={i}
                width="4px"
                height="4px"
                borderRadius="50%"
                bg={
                  i * 1 < (progress * dotCount) / 100 ? COLORS.red : "#6a6e8566"
                }
              />
            )
          })}
        </Flex>

        <Box
          position="absolute"
          left={`${progress}%`}
          transform="translateX(-50%)"
          fontSize="16px"
          color={COLORS.red}
        >
          <AirplaneProgress />
        </Box>
      </Flex>
    </Flex>
  )
}

export default FlightLoadingProgress
