import React, { useCallback, useEffect, useMemo } from "react"
import { Box, useBreakpointValue } from "@chakra-ui/react"

import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { format } from "date-fns"
import PrevNextArrows from "./PrevNextArrows"
import {
  CHART_BAR_DATA,
  CHART_TOOLTIP_DATE,
  MONTH_NAMES,
} from "../../../constants/constants"
import LoadingAnimation from "../../LoadingAnimation"

const ChartView = ({
  chartData,
  isFetchingChartData,
  parentWidth,
  setParentWidth,
}) => {
  const chartRef = React.useRef(null)
  const xAxisInterval = useBreakpointValue({ base: 3, xl: 2, "2xl": 1 })
  const handleResize = useCallback(() => {
    if (chartRef.current) {
      setParentWidth(chartRef.current.clientWidth)
    }
  }, [setParentWidth])

  useEffect(() => {
    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [handleResize, chartData, isFetchingChartData])

  const data = useMemo(() => {
    if (
      isFetchingChartData ||
      !chartData ||
      !Array.isArray(chartData.availability) ||
      chartData.availability.length === 0
    ) {
      return []
    }

    const sortData = chartData.availability
      .filter((item) => item && item.date)
      .sort((a, b) => new Date(a.date) - new Date(b.date))

    if (sortData.length === 0) {
      return []
    }

    const initialData = {
      economy: 0,
      premiumEconomy: 0,
      business: 0,
      first: 0,
    }
    const cDataLength =
      (new Date(sortData.at(-1).date) - new Date(sortData[0].date)) / 86_400_000
    const cData = [sortData[0]]
    let currentDate = new Date(cData[0].date)
    const hourOffset = new Date().getTimezoneOffset() / 60 / 24
    let count = 1
    for (let i = 1; i < cDataLength; i++) {
      const addDay = new Date(
        currentDate.setDate(currentDate.getDate() + 1 + -hourOffset)
      )
      if (
        addDay.toISOString() === new Date(sortData[count].date).toISOString()
      ) {
        cData.push(sortData[count])
        currentDate = addDay
        count++
      } else {
        cData.push({
          ...initialData,
          date: new Date(addDay.setHours(addDay.getHours() + -hourOffset)),
        })
      }
    }
    return cData
  }, [chartData, isFetchingChartData])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = format(new Date(label), CHART_TOOLTIP_DATE)

      return (
        <div
          style={{
            backgroundColor: "#FFF",
            padding: 5,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            boxShadow: "0 0 5px 0 #000",
          }}
        >
          <p>{date}</p>
          <div>
            {payload.map(({ name, value, fill }) => {
              return value === 0 ? null : (
                <div
                  key={name}
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <div
                    style={{ backgroundColor: fill, width: 10, height: 10 }}
                  />
                  <p>{`${value}`}</p>
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    return null
  }

  const monthTickFormatter = (tickProps) => {
    const {
      x,
      y,
      payload: { value },
    } = tickProps
    const date = new Date(value)
    const isWeekDay = date.getDay() === 0 || date.getDay() === 6
    return (
      <text
        x={x}
        y={y + 8}
        textAnchor="middle"
        fill={isWeekDay ? "red" : "black"}
        style={{ fontSize: 14 }}
      >
        {date.getDate()}
      </text>
    )
  }

  const renderQuarterTick = () => {
    let prevMonth = -1 // Initialize to -1 to ensure the first month label is printed

    return (tickProps) => {
      const { x, y, payload } = tickProps
      const { value, offset, index } = payload
      const date = new Date(value)
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()

      // Avoid printing labels for the last days of the month
      if (
        new Date(data[0].date).getMonth() !==
          new Date(data[6]?.date).getMonth() &&
        index < 7
      ) {
        return null
      }
      // Avoid printing labels for the last days of the month
      if (day >= 28) {
        return null
      }

      if (prevMonth !== month) {
        prevMonth = month

        const pathX = Math.floor(x - offset) + 0.5

        return (
          <g key={value}>
            <path d={`M${pathX},${y + 5}v${-15}`} stroke="black" />
            <text
              x={x + 36}
              y={y + 3}
              textAnchor="middle"
              style={{ fontSize: 14 }}
            >
              {MONTH_NAMES[month]} {year}
            </text>
          </g>
        )
      }

      return null
    }
  }

  const handleScroll = (direction) => {
    const container = chartRef.current
    const scrollStep = container.offsetWidth

    if (container) {
      if (direction === "left") {
        container.scrollLeft -= scrollStep
      } else if (direction === "right") {
        container.scrollLeft += scrollStep
      }
    }
  }

  if (isFetchingChartData) {
    return <LoadingAnimation />
  }

  const minWidth = data.length * (parentWidth / 90)

  return (
    <Box ref={chartRef} w={"100%"} h={"100%"} pb={1} overflowX={"auto"}>
      <ResponsiveContainer
        width={"100%"}
        height={"100%"}
        minWidth={data.length > 90 ? minWidth : "100%"}
      >
        <BarChart
          width={300}
          height={150}
          data={data}
          margin={{
            top: -10,
            right: 45,
            left: 5,
            bottom: 45,
          }}
        >
          <XAxis
            dataKey="date"
            interval={xAxisInterval}
            axisLine={false}
            tickLine={false}
            tick={monthTickFormatter}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={renderQuarterTick()}
            height={1}
            scale="band"
            xAxisId="quarter"
          />
          <YAxis hide />
          <Tooltip
            cursor={{ fill: "transparent" }}
            content={<CustomTooltip />}
          />
          {CHART_BAR_DATA.map(({ dataKey, fill, radius }) => (
            <Bar
              barGap={2}
              key={dataKey}
              stackId="a"
              radius={radius}
              dataKey={dataKey}
              fill={fill}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>

      <PrevNextArrows handleScroll={handleScroll} />
    </Box>
  )
}

export default ChartView
