import { useState, memo } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import parse from "date-fns/parse"
import format from "date-fns/format"
import {
  Button,
  Checkbox,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Show,
  Td,
  Text,
  Tr,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react"
import { RepeatIcon } from "@chakra-ui/icons"
import DeleteIcon from "../../../img/trash.svg"
import EditIcon from "../../../img/pen.svg"

import AlertComponent from "../../AlertsWithMap/components/AlertComponent"
import AlertRouteContent from "../../AlertRouteContent"
import { trackPage } from "../../../services/analytics"
import {
  ALERT_STATUS,
  COLORS,
  SUBSCRIPTION,
} from "../../../constants/constants"
import { deleteAlert, updateAlert } from "../../../services/api"
import { useAuthContext } from "../../../services/auth"

const DATE_FORMAT = "MMMM dd, yyyy"
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd"

const { MONTHLY, ANNUAL, ADMIN } = SUBSCRIPTION

const parseDate = (dateStr) => {
  const parsedDate = parse(dateStr, DEFAULT_DATE_FORMAT, new Date())
  return format(parsedDate, DATE_FORMAT)
}

const AlertRow = memo(
  ({
    userAlert,
    route,
    flightClasses,
    hourlyAlertsIcon,
    checkedItems,
    setCheckedItems,
  }) => {
    const { t } = useTranslation()
    const { user } = useAuthContext()
    const toast = useToast()
    const placement = useBreakpointValue({ base: "bottom", sm: "left" })
    const queryClient = useQueryClient()

    const [openAlerts, setOpenAlerts] = useState({})

    const isMonthlyOrAnnual =
      user &&
      [MONTHLY, ANNUAL, ADMIN].includes(user?.subscription?.toLowerCase())

    const { mutateAsync: updateAlertMutation } = useMutation({
      mutationFn: updateAlert,
      onSuccess: () => queryClient.invalidateQueries(["userAlerts"]),
    })

    const { mutateAsync: deleteAlertMutation } = useMutation({
      mutationFn: deleteAlert,
      onSuccess: () => {
        queryClient.invalidateQueries(["userAlerts"])
      },
    })

    const onOpen = (alertId) => {
      setOpenAlerts((prev) => ({ ...prev, [alertId]: true }))
    }

    const onClose = (alertId) => {
      setOpenAlerts((prev) => ({ ...prev, [alertId]: false }))
    }

    const onAlertDelete = async (userAlert) => {
      await deleteAlertMutation(userAlert.id)
      await queryClient.invalidateQueries(["me"])
      onClose()
    }

    const handleRepeatAlert = async (route) => {
      const formattedData = {
        status: "Pending",
      }

      try {
        await updateAlertMutation({ id: route.id, ...formattedData })

        toast({
          position: "bottom-right",
          title: t("alertRouteModal.toastSuccessUpdated"),
          status: "success",
        })
      } catch (err) {
        toast({
          position: "bottom-right",
          title: t("alertRouteModal.editToastError"),
          status: "error",
        })
      }
    }

    return (
      <Tr
        key={userAlert.id}
        sx={{
          "& > td": {
            fontSize: { base: "2xs", sm: "xs", md: "sm" },
            fontWeight: 500,
          },
        }}
      >
        <Td p={4}>
          <Checkbox
            colorScheme={"red"}
            top="1"
            aria-label="Select row"
            isChecked={checkedItems.includes(userAlert.id)}
            onChange={(e) =>
              setCheckedItems((prev) => {
                return e.target.checked
                  ? [...prev, userAlert.id]
                  : checkedItems.filter((id) => id !== userAlert.id)
              })
            }
          />
        </Td>

        <Td px={2} py={4} textAlign={"left"}>
          {userAlert.origin.name} - {userAlert.destination.name}
          {userAlert.pmax_alert && (
            <Image
              display={"inline-block"}
              ml={1}
              mb={"-2px"}
              src={hourlyAlertsIcon}
              width={4}
              height={4}
              alt={"Hourly alerts icon"}
            />
          )}
        </Td>

        <Td px={2} py={4}>
          {parseDate(userAlert.start_date)} - {parseDate(userAlert.end_date)}
        </Td>

        <Td px={2} py={4}>
          {flightClasses?.join(", ")}
        </Td>

        <Show above={"lg"}>
          <Td px={2} py={4}>
            {userAlert.pmax_alert
              ? "QF"
              : userAlert.preferred_programs?.join(", ").toUpperCase()}
          </Td>
        </Show>

        <Show above={"lg"}>
          <Td px={2} py={4}>
            {route.pmaxValue}
          </Td>
        </Show>

        <Td px={2} py={4}>
          <Flex alignItems={"center"} gap={2}>
            <Text
              fontWeight={700}
              p={"2px 8px"}
              borderRadius={"6px"}
              color={
                userAlert.status === ALERT_STATUS.pending
                  ? COLORS.green
                  : COLORS.red
              }
              bg={
                userAlert.status === ALERT_STATUS.pending
                  ? `${COLORS.green}1F`
                  : `${COLORS.red}1F`
              }
            >
              {userAlert.status === ALERT_STATUS.pending
                ? "Active"
                : userAlert.status}
            </Text>
            {(userAlert.status === ALERT_STATUS.sent ||
              userAlert.status === ALERT_STATUS.expired) && (
              <RepeatIcon
                cursor={"pointer"}
                width={4}
                height={4}
                onClick={() => handleRepeatAlert(route)}
                color={COLORS.secondary}
              />
            )}
          </Flex>
        </Td>
        <Td
          p={{ base: "16px 8px", lg: "21px 20px 22px 27px" }}
          textAlign="center"
        >
          <Flex
            flexDirection={{
              base: "column",
              xl: "row",
            }}
            gap={{ base: 1, sm: 2, md: 4 }}
            alignItems={"center"}
          >
            <Flex>
              <Image
                src={DeleteIcon}
                width={"24px"}
                height={"24px"}
                cursor="pointer"
                boxSize={{ base: 5, md: 6 }}
                color="#6a6e85"
                onClick={() => onOpen(userAlert.id)}
              />
              <Modal
                isOpen={openAlerts[userAlert.id]}
                onClose={() => onClose(userAlert.id)}
                isCentered
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader fontSize={"2xl"} fontWeight="bold">
                    {t("alerts.deleteHeader")}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>{t("alerts.deleteContent")}</ModalBody>

                  <ModalFooter>
                    <Flex width="100%" gap={5}>
                      <Button
                        flex={1}
                        textTransform={"uppercase"}
                        backgroundColor={COLORS.grey}
                        color={COLORS.red}
                        onClick={() => {
                          onClose(userAlert.id)
                        }}
                      >
                        {t("alerts.deleteCancel")}
                      </Button>
                      <Button
                        flex={1}
                        textTransform={"uppercase"}
                        backgroundColor={COLORS.red}
                        color={COLORS.white}
                        onClick={() => onAlertDelete(userAlert)}
                      >
                        {t("alerts.deleteAgree")}
                      </Button>
                    </Flex>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Flex>

            <Flex
              sx={{
                "& > div": {
                  minWidth: "unset!important",
                },
              }}
            >
              <Popover
                placement={placement}
                closeOnBlur={true}
                onOpen={() => {
                  trackPage({
                    title: "Alert Route",
                  })
                }}
              >
                {({ onClose }) => (
                  <>
                    <PopoverTrigger>
                      <Image
                        src={EditIcon}
                        cursor="pointer"
                        boxSize={{ base: 4, md: 5 }}
                        color="#6a6e85"
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      _focus={{ boxShadow: "none" }}
                      boxShadow="0px 10px 22px rgba(0, 0, 0, 0.14);"
                      borderRadius={8}
                      w={{ base: "100%", sm: 380 }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <PopoverCloseButton />
                      <PopoverBody p={0}>
                        {route.pmax_alert ? (
                          <AlertComponent
                            route={route}
                            onClose={onClose}
                            isMonthlyOrAnnual={isMonthlyOrAnnual}
                          />
                        ) : (
                          <AlertRouteContent route={route} onClose={onClose} />
                        )}
                      </PopoverBody>
                    </PopoverContent>
                  </>
                )}
              </Popover>
            </Flex>
          </Flex>
        </Td>
      </Tr>
    )
  }
)

export default AlertRow
