import {
  Box,
  Flex,
  Image,
  Link,
  ListItem,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
} from "@chakra-ui/react"
import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { differenceInDays, parseISO } from "date-fns"

import starMedalIcon from "../../../../img/star_medal.svg"
import twentyFourHoursIcon from "../../../../img/24_hours.svg"
import { COLORS } from "../../../../constants/constants"

const SEATS_DATA = [
  {
    cabin: "Economy",
    unsoldSeats: "At least 9",
  },
  {
    cabin: "Premium Economy",
    unsoldSeats: "At least 4",
  },
  {
    cabin: "Business",
    unsoldSeats: "At least 7",
  },
  {
    cabin: "First",
    unsoldSeats: "At least 6",
  },
  {
    cabin: "Unmapped",
    unsoldSeats: "Any",
  },
]

const PopoverBottom = ({ code, details, summaryPoints }) => {
  const { t } = useTranslation()

  const dayDifference = differenceInDays(
    parseISO(details[0].departure_date),
    new Date()
  )

  const getMaxSeatsCount = (data) => {
    if (data) {
      const seatNumbers = data.remaining_seats.map((seat) => {
        const parts = seat.split(":")
        return parseInt(parts[1], 10)
      })

      const maxSeatNumber = Math.max(...seatNumbers)

      return `At least ${maxSeatNumber}`
    }
  }

  return (
    <Flex
      flexDirection={"column"}
      width={"100%"}
      padding={"60px 60px 46px"}
      gap={10}
    >
      <Box
        bgGradient={`repeating-linear-gradient(-45deg, ${COLORS.grey} 0px, ${COLORS.grey} 60px, #FFFFFF 60px, #FFFFFF 120px)`}
        borderRadius={"20px"}
        padding={10}
        border={`4px solid ${COLORS.grey}`}
      >
        <Flex flexDirection={"column"} gap={4}>
          <Text fontWeight={600} fontSize={"20px"} lineHeight={"30px"}>
            <Trans
              i18nKey={"likelihood.popover.nearlyFull.title"}
              values={{
                code: details[0].aircraft_code + code,
                day: dayDifference,
              }}
              components={{ bold: <strong /> }}
            />
          </Text>

          <Table
            display={"inline-block"}
            border={"1px solid #e0e0e0"}
            borderRadius={"12px"}
            overflow={"hidden"}
          >
            <Thead backgroundColor={"#FAFAFB"}>
              <Tr boxShadow={"0 1px 3px 0px rgba(0, 0, 0, 0.1)"} isTruncated>
                <Th
                  fontSize={"14px"}
                  fontWeight={700}
                  color={"#141725"}
                  textTransform={"none"}
                  minWidth={"160px"}
                >
                  Cabin
                </Th>
                <Th
                  fontSize={"14px"}
                  fontWeight={700}
                  color={"#141725"}
                  textTransform={"none"}
                  minWidth={"150px"}
                >
                  Unsold Seats
                </Th>
                <Th
                  width={"100%"}
                  fontSize={"14px"}
                  fontWeight={700}
                  color={"#141725"}
                  textTransform={"none"}
                >
                  Fare Class Count
                </Th>
              </Tr>
            </Thead>

            <Tbody backgroundColor={COLORS.white}>
              {SEATS_DATA.map((seat, index) => {
                return (
                  <Tr
                    boxShadow={"0 1px 3px 0px rgba(0, 0, 0, 0.1)"}
                    key={index}
                    fontSize={"16px"}
                    fontWeight={700}
                  >
                    <Td border={"none"} isTruncated>
                      {seat.cabin}
                    </Td>
                    <Td border={"none"} isTruncated>
                      {getMaxSeatsCount(summaryPoints[seat.cabin])}
                    </Td>
                    <Td border={"none"}>
                      {summaryPoints[seat.cabin]?.remaining_seats
                        .join(", ")
                        .replace(/,\s*$/, "")}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Flex>
      </Box>
      <Flex flexDirection={"column"} gap={"30px"}>
        <Flex gap={4} alignItems={"flex-start"}>
          <Image src={starMedalIcon} width={"36px"} alt={"Star medal"} />

          <Flex gap={5} flexDirection={"column"} width={"100%"}>
            <Flex gap={4} width={"100%"} alignItems={"center"}>
              <Text fontSize={"24px"} fontWeight={700} lineHeight={"36px"}>
                Upgrade strategies before departure:
              </Text>
            </Flex>

            <Box>
              <UnorderedList
                display={"flex"}
                flexDirection={"column"}
                gap={2}
                fontSize={"20px"}
                fontWeight={600}
                marginLeft={8}
                lineHeight={"28px"}
              >
                <ListItem>
                  Consider buying a flexible ticket in the below class& request
                  upgrade
                </ListItem>
                <ListItem>
                  Bid for upgrade if this is offered by the operating airline
                </ListItem>
                <ListItem>
                  If you have status & points with the operating airline, ask
                  that these seats are designated as reward seats
                </ListItem>
              </UnorderedList>
            </Box>
          </Flex>
        </Flex>

        <Flex gap={4} alignItems={"flex-start"}>
          <Image src={twentyFourHoursIcon} width={"36px"} alt={"24 hours"} />

          <Flex gap={3} flexDirection={"column"} width={"100%"}>
            <Flex gap={5} flexDirection={"column"}>
              <Text fontSize={"24px"} fontWeight={700} lineHeight={"36px"}>
                Within 24 hrs of the flight:
              </Text>

              <Text fontSize={"20px"} fontWeight={600} marginBottom={3}>
                The remaining seats may be allocated by the operating airline as
                follows:
              </Text>
            </Flex>

            <UnorderedList
              display={"flex"}
              flexDirection={"column"}
              gap={5}
              fontSize={"20px"}
              fontWeight={600}
              marginLeft={8}
              lineHeight={"28px"}
            >
              <ListItem>Customers who have submitted the highest bids</ListItem>
              <ListItem>
                Upgrade requests based on class of ticket currently held
              </ListItem>
              <ListItem>Airline loyalty status</ListItem>
              <ListItem>Operational reasons</ListItem>
            </UnorderedList>
          </Flex>
        </Flex>

        <Text fontSize={"20px"} fontWeight={600} lineHeight={"30px"}>
          {t("likelihood.popover.nearlyFull.bottomText", { code: code })}{" "}
          <Link href="#" textDecoration={"underline"} fontWeight={700}>
            click here
          </Link>
          .
        </Text>
      </Flex>
    </Flex>
  )
}

export default PopoverBottom
