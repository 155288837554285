import { Flex, Text } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { COLORS } from "../../../constants/constants"
import { useTranslation } from "react-i18next"

const ProgressMessage = ({ step }) => {
  const { t } = useTranslation()
  const MotionText = motion.create(Text)

  const getMessage = () => {
    if (step === 0) return { key: "first", text: t("loadingProgress.first") }
    if (step === 1) return { key: "second", text: t("loadingProgress.second") }
    return { key: "third", text: t("loadingProgress.third") }
  }

  const { key, text } = getMessage()

  return (
    <Flex height={"24px"} position="relative" width={"100%"}>
      <AnimatePresence mode="wait">
        <MotionText
          key={key}
          color={COLORS.red}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3 }}
          position="absolute"
        >
          {text}
        </MotionText>
      </AnimatePresence>
    </Flex>
  )
}

export default ProgressMessage
