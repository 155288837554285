import range from "lodash/range"
import { parseISO, format } from "date-fns"

import QantasBookContent from "../components/QantasBookContent"
import VelocityBookContent from "../components/VelocityBookContent"
import AmericanBookContent from "../components/AmericanBookContent"
import UnitedBookContent from "../components/UnitedBookContent"
import AviosBookContent from "../components/AviosBookContent"
import AeroplanBookContent from "../components/AeroplanBookContent"
// import AlaskaBookContent from "../components/AlaskaBookContent"
// import DeltaSkymilesBookContent from "../components/DeltaSkymilesBookContent"
// import JetBlueBookContent from "../components/JetBlueBookContent"

import QFAwards from "../img/qantas_icon.svg"
import VAAwards from "../img/VA.svg"
import AAAwards from "../img/AA.svg"
import UAAwards from "../img/UA.svg"
import AVAwards from "../img/AV.svg"
import APAwards from "../img/AP.svg"
import { ANY, flightClassesMappingToCode } from "../constants/constants"
// import ALAwards from "../img/alaska.png"
// import DSAwards from "../img/delta_skymiles.png"
// import JBAwards from "../img/jet_blue.png"

export const getPagesToRender = (currentPage, pageCount) => {
  if (pageCount <= 6) {
    return range(1, pageCount + 1)
  }
  switch (currentPage) {
    case 1:
    case 2:
      return [1, 2, 3, "...", pageCount]
    case 3:
      return [1, 2, 3, 4, "...", pageCount]
    case 4:
      return [1, 2, 3, 4, 5, "...", pageCount]
    case pageCount - 4:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        pageCount,
      ]
    case pageCount - 3:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        pageCount,
      ]
    case pageCount - 2:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        pageCount,
      ]
    case pageCount - 1:
      return [1, "...", currentPage - 1, currentPage, pageCount]
    case pageCount:
      return [1, "...", currentPage - 1, currentPage]
    default:
      return [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        pageCount,
      ]
  }
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return string
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const handleFlightSelection = ({ from, to }) => {
  if (from && to) {
    const fromLabelCleaned = from.fullLabel
      .replace(/\([^)]*\)/g, "")
      .toLowerCase()
    const toLabelCleaned = to.fullLabel.replace(/\([^)]*\)/g, "").toLowerCase()

    const fromWordsArr = fromLabelCleaned
      .split(/\s+|[-/'’]+/)
      .filter((word) => /^[a-zA-Z]+$/.test(word))
    const toWordsArr = toLabelCleaned
      .split(/\s+|[-/'’]+/)
      .filter((word) => /^[a-zA-Z]+$/.test(word))

    let fromValidation = fromWordsArr.filter((word) => {
      const isAlphabetic = /^[a-zA-Z]+$/.test(word)
      return isAlphabetic && !["", " ", "-"].includes(word)
    })
    let toValidation = toWordsArr.filter((word) => {
      const isAlphabetic = /^[a-zA-Z]+$/.test(word)
      return isAlphabetic && !["", " ", "-"].includes(word)
    })

    const fromURL = fromValidation.join("-") + "-" + from.value
    const toURL = toValidation.join("-") + "-" + to.value

    return { fromURL, toURL }
  }
}

export const getCodeFromRoute = (route) => {
  const routeParts = route.split("-to-")
  if (routeParts.length === 2) {
    const [originRoute, destinationRoute] = routeParts

    const origin = originRoute.slice(-3).toLowerCase()
    const destination = destinationRoute.slice(-3).toLowerCase()

    const origin_city =
      originRoute.slice(-3).toLowerCase() === ANY.toLowerCase()
        ? originRoute.substring(0, originRoute.lastIndexOf("-"))
        : ""
    const destination_city =
      destinationRoute.slice(-3).toLowerCase() === ANY.toLowerCase()
        ? destinationRoute.substring(0, destinationRoute.lastIndexOf("-"))
        : ""

    return { origin, destination, origin_city, destination_city }
  }

  return null
}

export const generateRandomNumbers = (range) => {
  const numbers = []
  while (numbers.length < range) {
    const num = Math.floor(Math.random() * range)
    if (numbers.indexOf(num) === -1) numbers.push(num)
  }
  return numbers
}

export const formatTime = (dateStr) => {
  const utcDate = new Date(dateStr)
  const hours = String(utcDate.getUTCHours()).padStart(2, "0")
  const minutes = String(utcDate.getUTCMinutes()).padStart(2, "0")

  return `${hours}:${minutes}`
}

export const formatUTCDate = (dateStr, dateFormat) => {
  const date = parseISO(dateStr)
  const utcDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000)

  return format(utcDate, dateFormat)
}

export const getDatesOptions = () => {
  const dates = [{ label: "All", value: null }]
  const currentDate = new Date()

  for (let i = 0; i < 13; i++) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + i,
      1
    )
    dates.push({
      value: format(date, "yyyy-MM"),
      label: format(date, "MMM yy"),
    })
  }

  return dates
}

export const renderBookingDetails = (flight, highestPoint, t) => {
  const detailsMap = {
    QF: {
      airlineAwardImage: QFAwards,
      bookingContentComponent: (
        <QantasBookContent flight={flight} highestPoint={highestPoint} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "qantas",
        to: flight.destination.name,
      }),
      airline: "Qantas Airlines",
    },
    VA: {
      airlineAwardImage: VAAwards,
      bookingContentComponent: (
        <VelocityBookContent flight={flight} highestPoint={highestPoint} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "velocity",
        to: flight.destination.name,
      }),
      airline: "Velocity Airlines",
    },
    AA: {
      airlineAwardImage: AAAwards,
      bookingContentComponent: (
        <AmericanBookContent flight={flight} highestPoint={highestPoint} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "american",
        to: flight.destination.name,
      }),
      airline: "American Airlines",
    },
    UA: {
      airlineAwardImage: UAAwards,
      bookingContentComponent: (
        <UnitedBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "united",
        to: flight.destination.name,
      }),
      airline: "United Airlines",
    },
    AV: {
      airlineAwardImage: AVAwards,
      bookingContentComponent: (
        <AviosBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "avios",
        to: flight.destination.name,
      }),
      airline: "Avios Airlines",
    },
    AP: {
      airlineAwardImage: APAwards,
      bookingContentComponent: (
        <AeroplanBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "aeroplan",
        to: flight.destination.name,
      }),
      airline: "Aeroplan Airlines",
    },
    // AL: {
    //   airlineAwardImage: ALAwards,
    //   bookingContentComponent: (
    //     <AlaskaBookContent points={highestPoint.points} />
    //   ),
    //   airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
    //     program: "alaska",
    //     to: flight.destination.name,
    //   }),
    // airline: "Alaska Airlines",
    // },
    // DS: {
    //   airlineAwardImage: DSAwards,
    //   bookingContentComponent: (
    //     <DeltaSkymilesBookContent points={highestPoint.points} />
    //   ),
    //   airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
    //     program: "delta_skymiles",
    //     to: flight.destination.name,
    //   }),
    // airline: "Delta Skymiles Airlines",
    // },
    // JB: {
    //   airlineAwardImage: JBAwards,
    //   bookingContentComponent: (
    //     <JetBlueBookContent points={highestPoint.points} />
    //   ),
    //   airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
    //     program: "jet_blue",
    //     to: flight.destination.name,
    //   }),
    // airline: "Jetblue Airlines",
    // },
    default: {
      airlineAwardImage: QFAwards,
      bookingContentComponent: (
        <QantasBookContent points={highestPoint.points} />
      ),
      airlineAwardImageAlt: t(`australianFlights.howToBookImageAlt`, {
        program: "qantas",
        to: flight.destination.name,
      }),
      airline: "Qantas Airlines",
    },
  }

  return detailsMap[flight.source] || detailsMap.default
}

export const generateDynamicLink = ({
  source,
  baseUrl,
  queryParams,
  rawParams = {},
}) => {
  const strategies = {
    QF: (baseUrl, queryParams) => {
      const url = new URL(baseUrl)
      Object.keys(queryParams).forEach((key) => {
        url.searchParams.append(key, queryParams[key])
      })
      return url.toString()
    },
    VA: (baseUrl, queryParams) => {
      const [baseWithoutHash, hash] = baseUrl.split("#")
      const queryString = Object.keys(queryParams)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
        )
        .join("&")

      return `${baseWithoutHash}#${hash}?${queryString}`
    },
    AA: (baseUrl, queryParams, rawParams) => {
      const url = new URL(baseUrl)
      Object.keys(queryParams).forEach((key) => {
        url.searchParams.append(key, queryParams[key])
      })

      const rawQuery = Object.keys(rawParams)
        .map((key) => `${key}=${rawParams[key]}`)
        .join("&")

      return `${url.toString()}&${rawQuery}`
    },
    default: (baseUrl, queryParams) => {
      const url = new URL(baseUrl)
      Object.keys(queryParams).forEach((key) => {
        url.searchParams.append(key, queryParams[key])
      })
      return url.toString()
    },
  }

  const generateLink = strategies[source] || strategies.default

  return generateLink(baseUrl, queryParams, rawParams)
}

export const getParamsForSource = ({ flight, highestPoint }) => {
  const formatDateForURL = ({ flightDate, source }) => {
    const [year, month, day] = flightDate.split("T")[0].split("-")

    switch (source) {
      case "QF":
        return `${year}-${month}-${day}`
      case "VA":
        return `${month}-${day}-${year}`
      case "AA":
        return `${year}-${month}-${day}`
      default:
        return `${year}-${month}-${day}`
    }
  }

  const sourceParams = {
    QF: {
      queryParams: {
        departureAirportCode: flight.origin.code,
        arrivalAirportCode: flight.destination.code,
        departureDate: formatDateForURL({
          flightDate: flight.flight_start_date,
          source: flight.source,
        }),
        usePoints: true,
        int_cam: "n:en:cta:search+rewards+flight:classic+flight",
        tripType: "O",
        flexibleWithDates: true,
        travelClass: flightClassesMappingToCode[highestPoint.name],
        adults: 1,
        utm_source: "gyozaflights",
      },
    },
    VA: {
      queryParams: {
        journeyType: "one-way",
        locale: "en-GB",
        awardBooking: true,
        origin: flight.origin.code,
        destination: flight.destination.code,
        INF: 0,
        date: formatDateForURL({
          flightDate: flight.flight_start_date,
          source: flight.source,
        }),
        utm_source: "gyozaflights",
      },
    },
    AA: {
      queryParams: {
        locale: "en_US",
        pax: 1,
        adult: 1,
        type: "OneWay",
        searchType: "Award",
        cabin: "",
        carriers: "ALL",
        maxAwardSegmentAllowed: 3,
        utm_source: "gyozaflights",
      },
      rawParams: {
        slices: JSON.stringify([
          {
            orig: flight.origin.code,
            origNearby: false,
            dest: flight.destination.code,
            destNearby: false,
            date: formatDateForURL({
              flightDate: flight.flight_start_date,
              source: flight.source,
            }),
          },
        ]),
      },
    },
  }

  // Default to QF if no specific params exist for the source
  return sourceParams[flight.source] || sourceParams.QF
}
