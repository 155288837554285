import { useTranslation } from "react-i18next"
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import aAdvantage from "../img/aadvantage_logo.png"
import { handleTrackAirlineButton } from "../services/analytics"

const numberFormat = new Intl.NumberFormat()

const formatDateForURL = (isoDateString) => {
  const [year, month, day] = isoDateString.split("T")[0].split("-")
  return `${year}-${month}-${day}`
}

const generateDynamicLink = (baseUrl, queryParams, rawParams) => {
  const url = new URL(baseUrl)

  Object.keys(queryParams).forEach((key) => {
    url.searchParams.append(key, queryParams[key])
  })

  const rawQuery = Object.keys(rawParams)
    .map((key) => `${key}=${rawParams[key]}`)
    .join("&")

  return `${url.toString()}&${rawQuery}`
}

const AmericanBookContent = ({ flight, highestPoint }) => {
  const { t } = useTranslation()

  const howToBookAmericanLink = t("links.AA")

  const params = {
    locale: "en_US",
    pax: 1,
    adult: 1,
    type: "OneWay",
    searchType: "Award",
    cabin: "",
    carriers: "ALL",
    maxAwardSegmentAllowed: 3,
  }

  const rawParams = {
    slices: JSON.stringify([
      {
        orig: flight.origin.code,
        origNearby: false,
        dest: flight.destination.code,
        destNearby: false,
        date: formatDateForURL(flight.flight_start_date),
      },
    ]),
  }

  const dynamicAmericanLink = generateDynamicLink(
    howToBookAmericanLink,
    params,
    rawParams
  )

  const handleButtonClick = () => {
    handleTrackAirlineButton({ name: "American Airlines" })
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justify={"space-between"} gap={"42px"} alignItems={"center"}>
        <Box>
          <Image
            src={aAdvantage}
            height={"32px"}
            alt={"american_airlines_logo"}
          />
        </Box>
        <Box>
          <Text fontSize={"md"} fontWeight={"bold"} whiteSpace={"nowrap"}>
            {numberFormat.format(highestPoint.points)} {t("table.bookPoints")}
          </Text>
          <Text align={"right"} fontWeight={"bold"}>
            {t("table.bookTaxes")}
          </Text>
        </Box>
      </Flex>
      <Button
        as="a"
        href={dynamicAmericanLink}
        target="_blank"
        w={"100%"}
        height={"36px"}
        backgroundColor="#DD0000"
        color="white"
        borderRadius={8}
        boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
        onClick={handleButtonClick}
      >
        {t("table.bookButton")}
      </Button>
    </Flex>
  )
}

export default AmericanBookContent
