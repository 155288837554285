import axios from "axios"
import { ANY, CITY, SUBSCRIPTION } from "../constants/constants"

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export const API = axios.create({ baseURL: BACKEND_URL })
const { FREE } = SUBSCRIPTION

const publicOnlyURLS = [
  "/users/",
  "/jwt/create",
  "/users/reset_password/",
  "/users/reset_password_confirm/",
]

API.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken")
    if (
      accessToken &&
      !publicOnlyURLS.some((url) => config.url.endsWith(url))
    ) {
      config.headers["Authorization"] = `JWT ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

API.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    const errMessage = error.response?.data?.code
    const errDetail = error.response?.data?.detail
    if (
      errMessage === "token_not_valid" &&
      errDetail === "Given token not valid for any token type"
    ) {
      try {
        await refreshToken()
      } catch (err) {
        return Promise.reject(err)
      }
      return API(error.config)
    }

    if (
      errMessage === "token_not_valid" &&
      errDetail === "Token is invalid or expired"
    ) {
      logout()
    }
    return Promise.reject(error)
  }
)

export const login = async (values) => {
  const response = await API.post("/jwt/create", values)
  localStorage.setItem("accessToken", response.data.access)
  localStorage.setItem("refreshToken", response.data.refresh)
  return response.data
}

export const logout = () => {
  localStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
}

export const signup = async (values) => {
  const response = await API.post("/users/", values)
  return response.data
}

export const refreshToken = async () => {
  const token = localStorage.getItem("refreshToken")
  if (token) {
    const response = await API.post(`/jwt/refresh`, {
      refresh: token,
    })
    localStorage.setItem("accessToken", response.data.access)
  }
  throw new Error("NO_REFRESH_TOKEN")
}

export const me = async () => {
  const response = await API.get("/users/me/")
  return response.data
}

export const updateUser = async (values) => {
  const response = await API.put("/users/me/", values)
  return response.data
}

export const getFlights = async ({ queryKey }) => {
  const [, params] = queryKey

  const urlParams = new URLSearchParams(params)

  const response = await API.get(`/flights?${urlParams}`)

  return response.data
}

export const updateFlightData = async ({ queryKey }) => {
  const [, params] = queryKey

  const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = value
    }
    return acc
  }, {})

  const urlParams = new URLSearchParams(filteredParams)

  const response = await API.get(`/flight_update?${urlParams}`)

  return response.data
}

export const getAmadeusFlights = async ({ queryKey }) => {
  const [, params, user] = queryKey

  try {
    if (!user || user?.subscription.toLowerCase() === FREE) {
      const response = await API.get(`/amadeusflightdata`)
      return response.data
    }

    const filteredParams = Object.entries(params).reduce(
      (acc, [key, value]) => {
        if (value !== null) {
          acc[key] = value
        }
        return acc
      },
      {}
    )

    const urlParams = new URLSearchParams(filteredParams)
    const response = await API.get(`/amadeusflightdata?${urlParams}`)

    return response.data
  } catch (error) {
    if (!user || user?.subscription.toLowerCase() === FREE) {
      if (error.response.status === 402) {
        return error.response.data
      } else {
        if (error.response?.data?.error) {
          throw new Error(error.response.data.error)
        } else {
          throw new Error("An error occurred while fetching flights.")
        }
      }
    } else {
      if (error.response?.data?.error) {
        throw new Error(error.response.data.error)
      } else {
        throw new Error("An error occurred while fetching flights.")
      }
    }
  }
}

export const getAustralianOriginAirports = async () => {
  const response = await API.get("/regions-inclusions")
  return response.data
}

export const getAustralianFlights = async ({ queryKey }) => {
  const [, params] = queryKey

  const urlParams = new URLSearchParams(params)
  const response = await API.get(`/australia_flights/?${urlParams}`)

  return response.data
}

export const getChartData = async ({ queryKey }) => {
  const [, params] = queryKey

  const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value !== null) {
      acc[key] = encodeURIComponent(value)
    }
    return acc
  }, {})

  const urlParams = Object.keys(filteredParams)
    .map((key) => `${key}=${filteredParams[key]}`)
    .join("&")

  const response = await API.get(`/flightanalytics?${urlParams}`)

  return response.data
}

export const getOriginAirports = async () => {
  const response = await API.get(`/origins`)
  return response.data
}

export const getDestinationAirports = async ({ queryKey }) => {
  const [, origin] = queryKey

  const urlParams = origin
    ? origin.value === ANY
      ? `city=${origin.city}`
      : `origin=${origin.type === CITY ? origin.name : origin.value}`
    : ""
  const response = await API.get(`/destinations?${urlParams}`)

  return response.data
}

export const getAmadeusOriginAirports = async () => {
  const response = await API.get(`/amadeusairports`)

  return response.data
}

export const getFlightDates = async ({ queryKey }) => {
  const [, params] = queryKey
  const urlParams = new URLSearchParams(params)
  const response = await API.get(`/flight-dates?${urlParams}`)
  return response.data
}

export const getPmaxRoutes = async () => {
  const response = await API.get(`/pmax-routes`)
  return response.data
}

export const createAlert = async (data) => {
  const response = await API.post(`/user_alerts`, data)
  return response.data
}

export const getUserAlerts = async ({ queryKey }) => {
  const [, params] = queryKey
  const urlParams = new URLSearchParams(params)

  const response = await API.get(`/user_alerts?${urlParams}`)
  return response.data
}

export const updateAlert = async ({ id, ...data }) => {
  const response = await API.patch(`/user_alerts/${id}`, data)
  return response.data
}

export const deleteAlert = async (id) => {
  const response = await API.delete(`/user_alerts/${id}`)
  return response.data
}

export const deleteAlerts = async (data) => {
  const response = await API.delete(`/user_alerts/bulk-delete`, { data })
  return response.data
}

export const changePassword = async (data) => {
  const response = await API.post(`/users/set_password/`, data)
  return response.data
}

export const resetEmail = async (data) => {
  const response = await API.post(`/users/set_email/`, data)
  return response.data
}

export const resetPassword = async (data) => {
  const response = await API.post(`/users/reset_password/`, data)
  return response.data
}

export const resetPasswordConfirm = async (data) => {
  const response = await API.post(`/users/reset_password_confirm/`, data)
  return response.data
}

export const createCheckoutSession = async (data) => {
  const response = await API.post(`/users/checkout_session`, data)

  return response.data
}

export const getCheckoutResult = async ({ queryKey }) => {
  const [, userId, sessionId] = queryKey
  const response = await API.get(
    `/users/${userId}/session_result?session_id=${sessionId}`
  )
  return response.data
}

export const sendFeedback = async ({ queryKey }) => {
  const [, userId, feedbackData] = queryKey

  const response = await API.post(
    `/users/${userId}/send_feedback`,
    feedbackData
  )
  return response.data
}

export const cancelSubscription = async ({ queryKey }) => {
  const [, userId] = queryKey
  const response = await API.post(`/users/${userId}/cancel_subscription`)
  return response.data
}

export const userProposal = async ({ queryKey }) => {
  const [, { userId, request_text }] = queryKey

  const response = await API.post(`/users/${userId}/send_route_request/`, {
    request_text: request_text,
  })

  return response.data
}

export const getPricingPlans = async () => {
  const response = await API.get(`/plans`)
  return response.data
}

export const getPosts = async () => {
  const response = await API.get(`/homepage`)
  return response.data
}

export const getPost = async ({ queryKey }) => {
  const [, url] = queryKey

  const response = await API.get(`/posts/${url}`)
  return response.data
}

export const fetchBillingRedirect = async ({ userId }) => {
  const response = await API.get(`/users/${userId}/billing-redirect`)
  return response.data
}

export const subscribeToNewsletter = async (data) => {
  const response = await API.post("/newsletter-subscriptions", data)
  return response.data
}
