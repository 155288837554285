import { useTranslation } from "react-i18next"
import { Button, Flex, Image, Box, Text } from "@chakra-ui/react"
import aviosImage from "../img/avios.png"
import { handleTrackAirlineButton } from "../services/analytics"

const numberFormat = new Intl.NumberFormat()

const AviosBookContent = ({ points }) => {
  const { t } = useTranslation()

  const howToBookAviosLink = t("links.AV")

  const handleButtonClick = () => {
    handleTrackAirlineButton({ name: "Avios Airlines" })
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justify={"space-between"} gap={"42px"} alignItems={"center"}>
        <Box>
          <Image src={aviosImage} height={"40px"} alt={"avios_logo"} />
        </Box>
        <Box>
          <Text fontSize={"md"} fontWeight={"bold"} whiteSpace={"nowrap"}>
            {numberFormat.format(points)} {t("table.bookPoints")}
          </Text>
          <Text align={"right"} fontWeight={"bold"}>
            {t("table.bookTaxes")}
          </Text>
        </Box>
      </Flex>
      <Button
        as="a"
        href={howToBookAviosLink}
        target="_blank"
        w={"100%"}
        height={"36px"}
        backgroundColor="#DD0000"
        color="white"
        borderRadius={8}
        boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
        onClick={handleButtonClick}
      >
        {t("table.bookButton")}
      </Button>
    </Flex>
  )
}

export default AviosBookContent
