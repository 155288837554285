import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Flex,
  Text,
  Show,
  Image,
  Checkbox,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useBreakpointValue,
  Tooltip,
} from "@chakra-ui/react"

import { ALERTS_PER_PAGE, COLORS } from "../../constants/constants"
import { deleteAlerts, getUserAlerts } from "../../services/api"
import NoAlertsGuidance from "./components/NoAlertsGuidance"
import AlertRow from "./components/AlertRow"
import { alertsPageSeoTags } from "../../helpers/seoTags"
import LoadingAnimation from "../LoadingAnimation"
import Footer from "../Footer"
import Pagination from "../Pagination"
import usePagination from "../../hooks/usePagination"
import hourlyAlertsIcon from "../../img/economy_icon.png"
import DeleteIcon from "../../img/trash.svg"
import { pickBy } from "lodash"

const flightClassesMapping = {
  Economy: "economy",
  PremiumEconomy: "premiumEconomy",
  Business: "business",
  First: "first",
}

const Alerts = () => {
  const { t } = useTranslation()
  const { page, onPrev, onNext, onCurrent } = usePagination()
  const [searchParams] = useSearchParams()
  const queryClient = useQueryClient()

  const isMobile = useBreakpointValue({ base: true, lg: false })

  const [checkedItems, setCheckedItems] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [filter, setFilter] = useState(null)

  const params = pickBy({
    page: searchParams.get("page") || 1,
    status: filter,
  })

  const { data: userAlerts, isFetching: isFetchingUserAlerts } = useQuery({
    queryKey: ["userAlerts", params],
    queryFn: getUserAlerts,
    initialData: [],
  })

  const totalPages = Math.ceil((userAlerts.count || 0) / ALERTS_PER_PAGE)
  const handleOnNext = () => onNext(totalPages)

  const allChecked = checkedItems.length === userAlerts?.results?.length
  const isIndeterminate = checkedItems.length > 0 && !allChecked

  const handleToggleModal = () => {
    setModalOpen((prevState) => !prevState)
  }

  const { mutateAsync: deleteAlertsMutation } = useMutation({
    mutationFn: deleteAlerts,
    onSuccess: () => {
      queryClient.invalidateQueries(["userAlerts"])
    },
  })

  const handleRemoveAlerts = async () => {
    await deleteAlertsMutation({ ids: checkedItems })

    setCheckedItems([])
    setModalOpen(false)
  }

  return (
    <>
      {alertsPageSeoTags()}

      <Flex
        bg={COLORS.grey}
        flexGrow={1}
        minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box
          bg={COLORS.grey}
          px={{ base: 4, lg: 0 }}
          marginInlineStart={0}
          flexGrow={1}
        >
          <Flex mb={6}>
            <Heading
              as="h1"
              color={COLORS.black}
              textAlign="left"
              fontSize={{ base: "xl", lg: "2xl" }}
            >
              {t("alerts.header")}
            </Heading>
          </Flex>

          {isFetchingUserAlerts ? (
            <LoadingAnimation />
          ) : (
            <Box bg="white" borderRadius={12} mb={7}>
              <Flex
                p={4}
                flexDirection={{ base: "column", sm: "row" }}
                justifyContent={"space-between"}
                alignItems={{ base: "flex-start", sm: "center" }}
                gap={4}
              >
                <Flex
                  bg={COLORS.grey}
                  p={"2px"}
                  borderRadius={12}
                  gap={4}
                  sx={{
                    "& p": {
                      fontSize: "14px",
                      fontWeight: 700,
                    },
                  }}
                >
                  <Button
                    color={filter ? COLORS.secondary : COLORS.red}
                    bg={filter ? "inherit" : COLORS.white}
                    boxShadow={filter ? "none" : "0 2px 5px 0 rgba(0,0,0,0.1)"}
                    onClick={() => setFilter(null)}
                  >
                    <Text>All Alerts</Text>
                  </Button>
                  <Button
                    color={filter === "Sent" ? COLORS.red : COLORS.secondary}
                    bg={filter === "Sent" ? COLORS.white : "inherit"}
                    boxShadow={
                      filter === "Sent" ? "0 2px 5px 0 rgba(0,0,0,0.1)" : "none"
                    }
                    onClick={() => setFilter("Sent")}
                  >
                    <Text>Sent</Text>
                  </Button>
                  <Button
                    color={filter === "Pending" ? COLORS.red : COLORS.secondary}
                    bg={filter === "Pending" ? COLORS.white : "inherit"}
                    boxShadow={
                      filter === "Pending"
                        ? "0 2px 5px 0 rgba(0,0,0,0.1)"
                        : "none"
                    }
                    onClick={() => setFilter("Pending")}
                  >
                    <Text>Active</Text>
                  </Button>
                </Flex>

                <Flex gap={2} alignItems={"center"}>
                  <Image
                    src={hourlyAlertsIcon}
                    width={4}
                    height={4}
                    alt={"Hourly alerts icon"}
                  />

                  <Text color={COLORS.black}>denotes hourly search</Text>
                </Flex>
              </Flex>

              {userAlerts.count > 0 ? (
                <>
                  <Table
                    width={"100%"}
                    sx={{ tableLayout: "fixed" }}
                    textAlign={"left"}
                  >
                    <Thead>
                      <Tr
                        boxShadow="0px 2px 8px rgba(20, 23, 37, 0.08)"
                        borderTopRadius="12px"
                        sx={{
                          "& > th": {
                            fontSize: { base: "2xs", sm: "xs" },
                            fontWeight: 700,
                            color: COLORS.black,
                            whiteSpace: "nowrap",
                          },
                        }}
                      >
                        <Th p={4} w={{ base: "48px" }}>
                          <Checkbox
                            colorScheme={"red"}
                            isChecked={allChecked}
                            isIndeterminate={isIndeterminate}
                            onChange={(e) =>
                              setCheckedItems(
                                e.target.checked
                                  ? userAlerts.results.map(
                                      (userAlert) => userAlert.id
                                    )
                                  : []
                              )
                            }
                          />
                        </Th>
                        {checkedItems.length > 0 ? (
                          <>
                            <th
                              style={{ padding: "6px 8px", width: "100%" }}
                              colSpan={
                                isMobile || !(allChecked || isIndeterminate)
                                  ? 3
                                  : 2
                              }
                            >
                              <Flex columnGap={"14px"} alignItems="center">
                                <Text
                                  fontSize={"inherit"}
                                  textTransform={"none"}
                                  fontWeight={500}
                                >
                                  {checkedItems.length} Items selected
                                </Text>

                                <Flex>
                                  <Button
                                    onClick={handleToggleModal}
                                    fontSize={"inherit"}
                                    height={"fit-content"}
                                    gap={2}
                                    p={2}
                                    bg={"inherit"}
                                  >
                                    <Image
                                      src={DeleteIcon}
                                      boxSize={{ base: 4, md: 5 }}
                                      width={"20px"}
                                      height={"20px"}
                                      alt={"remove icon"}
                                    />
                                    <Text
                                      textTransform={"none"}
                                      fontSize={"inherit"}
                                      color={"#6A6E85"}
                                      lineHeight={"16px"}
                                    >
                                      Delete
                                    </Text>
                                  </Button>
                                  <Modal
                                    isOpen={isModalOpen}
                                    onClose={handleToggleModal}
                                    isCentered
                                  >
                                    <ModalOverlay />
                                    <ModalContent>
                                      <ModalHeader
                                        fontSize={"2xl"}
                                        fontWeight="bold"
                                      >
                                        {t("alerts.deleteHeader")}
                                      </ModalHeader>
                                      <ModalCloseButton />
                                      <ModalBody>
                                        {t("alerts.deleteSelectedContent")}
                                      </ModalBody>

                                      <ModalFooter>
                                        <Flex width="100%" gap={5}>
                                          <Button
                                            flex={1}
                                            textTransform={"uppercase"}
                                            backgroundColor={COLORS.grey}
                                            color={COLORS.red}
                                            onClick={handleToggleModal}
                                          >
                                            {t("alerts.deleteCancel")}
                                          </Button>
                                          <Button
                                            flex={1}
                                            textTransform={"uppercase"}
                                            backgroundColor={COLORS.red}
                                            color={COLORS.white}
                                            onClick={handleRemoveAlerts}
                                          >
                                            {t("alerts.deleteAgree")}
                                          </Button>
                                        </Flex>
                                      </ModalFooter>
                                    </ModalContent>
                                  </Modal>
                                </Flex>
                              </Flex>
                            </th>

                            <Show above="lg">
                              <Th
                                textTransform="none"
                                px={2}
                                py={4}
                                w={{ base: "27%", lg: "29%" }}
                              ></Th>
                              <Th
                                textTransform="none"
                                px={2}
                                py={4}
                                w={{ base: "12%", lg: "12%" }}
                              ></Th>

                              <Th
                                textTransform="none"
                                px={2}
                                py={4}
                                w={{ base: "14%", lg: "90px" }}
                              ></Th>
                            </Show>

                            <Th
                              textTransform="none"
                              px={2}
                              py={4}
                              w={"70px"}
                            ></Th>
                            <Th
                              textTransform="none"
                              px={2}
                              py={4}
                              w={{
                                base: "50px",
                                lg: "70px",
                                xl: "107px",
                              }}
                            ></Th>
                          </>
                        ) : (
                          <>
                            <Th
                              textTransform="none"
                              px={2}
                              py={4}
                              w={{ base: "26%", lg: "20%" }}
                            >
                              {t("alerts.itinerary")}
                            </Th>
                            <Th
                              textTransform="none"
                              px={2}
                              py={4}
                              w={{ base: "30%", lg: "16%" }}
                            >
                              {t("alerts.dateRange")}
                            </Th>

                            <Th
                              textTransform="none"
                              px={2}
                              py={4}
                              w={{ base: "27%", lg: "30%" }}
                            >
                              {t("alerts.class")}
                            </Th>

                            <Show above={"lg"}>
                              <Th
                                textTransform="none"
                                px={2}
                                py={4}
                                w={{ base: "12%", lg: "12%" }}
                              >
                                {t("alerts.program")}
                              </Th>

                              <Th
                                textTransform="none"
                                px={2}
                                py={4}
                                w={{ base: "14%", lg: "90px" }}
                              >
                                {t("alerts.passengers")}
                              </Th>
                            </Show>

                            <Th textTransform="none" px={2} py={4} w={"70px"}>
                              <Tooltip
                                label={
                                  <>
                                    <Text>
                                      {" "}
                                      <b>Invalid:</b> Route is either not valid
                                      for the city pair or not supported by the
                                      program
                                    </Text>
                                    <Text>
                                      {" "}
                                      <b>Outdated:</b> Search date range has
                                      passed
                                    </Text>
                                    <Text>
                                      {" "}
                                      <b>Expired:</b> We searched for months,
                                      but found nothing - please reinstate the
                                      alert
                                    </Text>
                                    <Text>
                                      {" "}
                                      <b>Active:</b> Actively monitoring this
                                      route for availability
                                    </Text>
                                  </>
                                }
                                placement="top"
                                hasArrow
                                bg="gray.700"
                                color="white"
                                fontSize="sm"
                                p={3}
                                borderRadius="md"
                                maxW="250px"
                              >
                                <Text cursor="help">{t("alerts.status")}</Text>
                              </Tooltip>
                            </Th>

                            <Th
                              textTransform="none"
                              px={2}
                              py={4}
                              w={{
                                base: "50px",
                                lg: "70px",
                                xl: "107px",
                              }}
                            ></Th>
                          </>
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userAlerts.results.map((userAlert) => {
                        const flightClasses = userAlert.flight_classes.map(
                          (flightClass) =>
                            t(`table.${flightClassesMapping[flightClass]}`)
                        )

                        const route = {
                          id: userAlert.id,
                          origin: userAlert.origin,
                          destination: userAlert.destination,
                          startDate: userAlert.start_date,
                          endDate: userAlert.end_date,
                          pmax_alert: userAlert.pmax_alert,
                          flightClasses: userAlert.flight_classes,
                          preferredPrograms: userAlert.preferred_programs || [],
                          isDirect: userAlert.direct,
                          status: userAlert.status,
                          isNew: false,
                          pmaxValue: userAlert.pmax_value || 1,
                        }

                        return (
                          <AlertRow
                            key={userAlert.id}
                            userAlert={userAlert}
                            route={route}
                            flightClasses={flightClasses}
                            hourlyAlertsIcon={hourlyAlertsIcon}
                            checkedItems={checkedItems}
                            setCheckedItems={setCheckedItems}
                          />
                        )
                      })}
                    </Tbody>
                  </Table>

                  <Pagination
                    page={page}
                    onCurrent={onCurrent}
                    onNext={handleOnNext}
                    onPrev={onPrev}
                    totalPages={totalPages}
                  />
                </>
              ) : (
                <NoAlertsGuidance />
              )}
            </Box>
          )}
        </Box>
        <Footer />
      </Flex>
    </>
  )
}

export default Alerts
