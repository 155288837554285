import { Flex } from "@chakra-ui/react"

import { useAuthContext } from "../services/auth"
import { COLORS } from "../constants/constants"
import Footer from "../components/Footer"
import Billing from "../components/Settings/components/Billing"
import PricingPlans from "../components/PricingPlans"
import PersonalDetails from "../components/Settings/components/PersonalDetails"
import FacebookSupport from "../components/FacebookSupport"

export default function Settings() {
  const { user } = useAuthContext()

  return (
    <Flex
      bg={COLORS.grey}
      minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
      flexDirection={"column"}
      px={{ base: 4, lg: 0 }}
      gap={"28px"}
    >
      <PersonalDetails user={user} />

      <Billing user={user} />

      <PricingPlans settingsPage={true} />

      <Footer />

      {!!user && <FacebookSupport />}
    </Flex>
  )
}
