import { useState, useEffect, useCallback, useMemo, useRef } from "react"
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { Flex } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { pickBy } from "lodash"
import { format } from "date-fns"

import {
  getChartData,
  getDestinationAirports,
  getFlights,
  getOriginAirports,
  updateFlightData,
} from "../services/api"
import { useAuthContext } from "../services/auth"
import { handleTrackSearch, trackPage } from "../services/analytics"
import {
  ANY,
  CITY,
  SUBSCRIPTION,
  defaultFlightsTexts,
  top100AirportCodes,
} from "../constants/constants"
import {
  generateRandomNumbers,
  getCodeFromRoute,
  handleFlightSelection,
} from "../helpers/functions"
import { mainPageSeoTags } from "../helpers/seoTags"
import Head from "../components/Flights/components/Head"
import PopularFlights from "../components/PopularFlights"
import FilterPanel from "../components/Flights/FilterPanel"
import FlightDataContext from "../components/Flights/FlightDataContext"
import GyozaFlightSearchHeader from "../components/Flights/GyozaFlightSearchHeader"
import FAQsBetweenFlights from "../components/Flights/components/FAQsBetweenFlights"
import RemainingSeatsChart from "../components/Flights/components/RemainingSeatsChart"
import AllAvailabilityLayout from "../components/Flights/components/AllAvailabilityLayout"
import FacebookSupport from "../components/FacebookSupport"
import "../App.css"

const randomNumbers = generateRandomNumbers(100)

const AllAvailability = () => {
  const { route } = useParams()
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const [searchParams, setSearchParams] = useSearchParams()

  const [dateModified, setDateModified] = useState(false)
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1)
  const [parentWidth, setParentWidth] = useState(0)
  const [updateFlightsError, setUpdateFlightsError] = useState(false)
  const [finishedFlightUpdate, setFinishedFlightUpdate] = useState(false)
  const [isDesktop, setIsDesktop] = useState(window?.innerWidth > 991)
  const [chartFilters, setChartFilters] = useState({
    aircraft: null,
    source: null,
  })
  const [top10Airports, setTop10Airports] = useState(null)
  const [flightsTexts, setFlightsTexts] = useState(null)
  const [filters, setFilters] = useState({
    isOneWay: true,
    passengerCount: 1,
    from: null,
    to: null,
    start_date: new Date(),
    end_date: new Date(),
    is_reversed: false,
    program: "All",
    stops: "1",
  })

  const isFreePlan =
    !user ||
    [SUBSCRIPTION.FREE, null].includes(user?.subscription.toLowerCase())

  const isRequested = !!(filters.from && filters.to && filters.start_date)

  const originAirportsQuery = useQuery({
    queryKey: ["originAirports"],
    queryFn: getOriginAirports,
    initialData: [],
  })

  const destinationAirportsQuery = useQuery({
    queryKey: ["destinationAirports", filters.from],
    queryFn: getDestinationAirports,
    initialData: [],
    enabled: !!filters.from,
  })

  const reversedDestinationsAirportsQuery = useQuery({
    queryKey: ["reversedDestinationsAirports", filters.to],
    queryFn: getDestinationAirports,
    initialData: [],
    enabled: !!filters.to,
  })

  const handleAddInitialFrom = useCallback(() => {
    if (originAirportsQuery?.data?.length > 0) {
      const from = originAirportsQuery.data.find((f) => f.code === "SYD")

      setFilters((prevFilters) => ({
        ...prevFilters,
        isOneWay: true,
        to: null,
        start_date: new Date(),
        end_date: new Date(),
        is_reversed: false,
        from: from
          ? {
              value: from.code,
              label: `${from.city} (${from.type === CITY ? ANY : from.code})`,
              fullLabel: `${from.name} (${from.code})`,
              city: from.city,
              type: from.type,
              name: from.name,
            }
          : null,
      }))
    }
  }, [originAirportsQuery?.data])

  const params = pickBy({
    page: searchParams.get("page") || 1,
    start_date: filters.start_date
      ? format(filters.start_date, "yyyy-MM-dd")
      : null,
    end_date: filters.end_date ? format(filters.end_date, "yyyy-MM-dd") : null,
    origin:
      filters.from?.type === CITY ? filters.from?.name : filters.from?.value,
    destination:
      filters.to?.type === CITY ? filters.to?.name : filters.to?.value,
    origin_city: filters.from?.value === ANY ? filters.from.city : null,
    destination_city: filters.to?.value === ANY ? filters.to.city : null,
    reversed: filters.is_reversed ? "true" : null,
    program: filters.program,
    stops: filters.stops,
  })

  const update_flight_params = useMemo(
    () =>
      pickBy({
        page: searchParams.get("page") || 1,
        start_date: filters.start_date
          ? format(filters.start_date, "yyyy-MM-dd")
          : null,
        end_date: filters.end_date
          ? format(filters.end_date, "yyyy-MM-dd")
          : null,
        origin:
          filters.from?.type === CITY
            ? filters.from?.name
            : filters.from?.value,
        destination:
          filters.to?.type === CITY ? filters.to?.name : filters.to?.value,
        origin_city: filters.from?.value === ANY ? filters.from.city : null,
        destination_city: filters.to?.value === ANY ? filters.to.city : null,
        reversed: filters.is_reversed ? "true" : null,
        program: filters.program,
        stops: filters.stops,
        source: "ALL",
      }),
    [
      searchParams,
      filters.start_date,
      filters.end_date,
      filters.from,
      filters.to,
      filters.is_reversed,
      filters.program,
      filters.stops,
    ]
  )

  const chartParams = pickBy({
    origin:
      filters.from?.type === CITY ? filters.from?.name : filters.from?.value,
    destination:
      filters.to?.type === CITY ? filters.to?.name : filters.to?.value,
    origin_city: filters.from?.value === ANY ? filters.from.city : null,
    destination_city: filters.to?.value === ANY ? filters.to.city : null,
    aircraft_details: chartFilters?.aircraft || null,
    source: chartFilters?.source || null,
  })

  const { data: chartData, isFetching: isFetchingChartData } = useQuery({
    queryKey: ["chartData", chartParams],
    queryFn: getChartData,
    keepPreviousData: true,
    initialData: {
      all_sources: [],
      all_aircraft_details: [],
      availability: [],
    },
    enabled: !!(
      params.origin &&
      params.destination &&
      ((filters.isOneWay && !!params.start_date) ||
        (!filters.isOneWay && !!params.start_date && !!params.end_date))
    ),
  })

  const { data: flightsData, isFetching } = useQuery({
    queryKey: ["flights", params],
    queryFn: getFlights,
    keepPreviousData: true,
    initialData: { count: 0, results: null },
    enabled: !!(
      params.origin &&
      params.destination &&
      ((filters.isOneWay && !!params.start_date) ||
        (!filters.isOneWay && !!params.start_date && !!params.end_date))
    ),
  })

  const { data: updatedFlightData, isFetching: isFetchingUpdatedFlightData } =
    useQuery({
      queryKey: ["updatedFlightData", update_flight_params],
      queryFn: updateFlightData,
      initialData: { count: 0, results: null },
      retry: false,
      enabled:
        !isFreePlan &&
        !!flightsData?.results &&
        dateModified &&
        !finishedFlightUpdate,
      onSettled: () => {
        setFinishedFlightUpdate(true)
      },
      onError: () => {
        setUpdateFlightsError(true)
      },
    })

  const flights =
    finishedFlightUpdate && !searchParams.get("page") && !updateFlightsError
      ? updatedFlightData
      : flightsData

  useEffect(() => {
    trackPage({ title: "All Rewards Availability" })
  }, [])

  useEffect(() => {
    if (filters.from && filters.to) {
      handleTrackSearch({
        origin: filters.from,
        destination: filters.to,
      })
    }
  }, [filters.from, filters.to])

  const isInitialMount = useRef(true)
  const isInitialMountDefaultFrom = useRef(true)

  useEffect(() => {
    if (filters.to && destinationAirportsQuery.isFetched) {
      if (destinationAirportsQuery?.data?.length > 0) {
        const availableDestination = destinationAirportsQuery.data.find(
          (airport) => airport.code === filters.to?.value
        )

        if (!availableDestination) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            to: null,
          }))
        }
      } else {
        setFilters((prevFilters) => ({
          ...prevFilters,
          to: null,
        }))
      }
    }
  }, [
    destinationAirportsQuery.isFetched,
    destinationAirportsQuery.data,
    filters.to,
  ])

  useEffect(() => {
    if (isInitialMount.current) {
      const currentParams = new URLSearchParams(searchParams)
      currentParams.delete("page")

      setSearchParams(currentParams)
      isInitialMount.current = false
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    const pageNumber = searchParams.get("page")
    if (currentPage !== pageNumber) {
      setCurrentPage(pageNumber)
    }
  }, [currentPage, searchParams])

  useEffect(() => {
    if (
      route &&
      originAirportsQuery?.data?.length > 0 &&
      destinationAirportsQuery?.data?.length > 0
    ) {
      const codes = getCodeFromRoute(route)

      if (codes) {
        const from = originAirportsQuery.data.find((f) => {
          if (codes.origin.toLowerCase() !== ANY.toLowerCase()) {
            return f.code.toLowerCase() === codes.origin
          } else {
            const fromWordsArr = f.city
              .toLowerCase()
              .split(/\s+|[-/'’]+/)
              .filter((word) => /^[a-zA-Z]+$/.test(word))

            const fromValidation = fromWordsArr
              .filter((word) => {
                const isAlphabetic = /^[a-zA-Z]+$/.test(word)
                return isAlphabetic && !["", " ", "-"].includes(word)
              })
              .join("-")

            return (
              fromValidation === codes.origin_city.toLowerCase() &&
              f.type === CITY
            )
          }
        })

        const to = destinationAirportsQuery.data.find((t) => {
          if (codes.destination.toLowerCase() !== ANY.toLowerCase()) {
            return t.code.toLowerCase() === codes.destination
          } else {
            const toWordsArr = t.city
              .toLowerCase()
              .split(/\s+|[-/'’]+/)
              .filter((word) => /^[a-zA-Z]+$/.test(word))

            const toValidation = toWordsArr
              .filter((word) => {
                const isAlphabetic = /^[a-zA-Z]+$/.test(word)
                return isAlphabetic && !["", " ", "-"].includes(word)
              })
              .join("-")

            return (
              toValidation === codes.destination_city.toLowerCase() &&
              t.type === CITY
            )
          }
        })

        if (from && to) {
          setFlightsTexts({
            from: {
              ...from,
              name:
                from.type === CITY
                  ? `${from.name.replace(" (All)", "")}`
                  : from.name,
              fullName:
                from.type === CITY
                  ? `${from.name.replace("(All)", `(${from.code})`)}`
                  : `${from.name.trim()} (${from.code})`,
            },
            to: {
              ...to,
              name:
                to.type === CITY ? `${to.name.replace(" (All)", "")}` : to.name,
              fullName:
                to.type === CITY
                  ? `${to.name.replace("(All)", `(${to.code})`)}`
                  : `${to.name.trim()} (${to.code})`,
            },
          })

          const top10 = randomNumbers.reduce((acc, number) => {
            if (acc.length >= 10) return acc

            const airport = reversedDestinationsAirportsQuery?.data?.find(
              (airport) => airport.code === top100AirportCodes[number]
            )

            if (airport) {
              acc.push({
                name:
                  airport.type === CITY
                    ? airport.name.replace(" (All)", "")
                    : airport.name.trim(),
                code: airport.code,
              })
            }

            return acc
          }, [])

          setTop10Airports(top10)
        } else {
          handleAddInitialFrom()
          navigate("/")
        }
      }
    } else {
      if (destinationAirportsQuery?.data?.length > 0) {
        const top10 = randomNumbers.reduce((acc, number) => {
          if (acc.length >= 10) return acc

          const airport = destinationAirportsQuery.data.find(
            (airport) => airport.code === top100AirportCodes[number]
          )

          if (airport) {
            acc.push({
              name:
                airport.type === CITY
                  ? airport.name.replace(" (All)", "")
                  : airport.name.trim(),
              code: airport.code,
            })
          }

          return acc
        }, [])

        setTop10Airports(top10)
      }
    }

    if (!route) {
      setFlightsTexts(null)
    }
  }, [
    route,
    originAirportsQuery.data,
    destinationAirportsQuery.data,
    reversedDestinationsAirportsQuery.data,
    isRequested,
    handleAddInitialFrom,
    navigate,
  ])

  useEffect(() => {
    if (
      !route &&
      originAirportsQuery?.data?.length > 0 &&
      isInitialMountDefaultFrom.current
    ) {
      handleAddInitialFrom()
      isInitialMountDefaultFrom.current = false
    }
  }, [route, handleAddInitialFrom, originAirportsQuery?.data])

  useEffect(() => {
    if (route) {
      const codes = getCodeFromRoute(route)

      if (codes) {
        const from = originAirportsQuery.data.find((airport) => {
          if (codes.origin.toLowerCase() !== ANY.toLowerCase()) {
            return airport.code.toLowerCase() === codes.origin
          } else {
            const airportWordsArr = airport.city
              .toLowerCase()
              .split(/\s+|[-/'’]+/)
              .filter((word) => /^[a-zA-Z]+$/.test(word))

            const airportValidation = airportWordsArr
              .filter((word) => {
                const isAlphabetic = /^[a-zA-Z]+$/.test(word)
                return isAlphabetic && !["", " ", "-"].includes(word)
              })
              .join("-")

            return (
              airportValidation === codes.origin_city.toLowerCase() &&
              airport.type === CITY
            )
          }
        })

        if (from) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            from: {
              value: from.code,
              label: `${from.city} (${from.type === CITY ? ANY : from.code})`,
              fullLabel:
                from.type === CITY
                  ? `${from.name.replace("(All)", `(${from.code})`)}`
                  : `${from.name} (${from.code})`,
              city: from.city,
              type: from.type,
              name: from.name,
            },
          }))
        }
      } else {
        navigate("/")
      }
    }
  }, [navigate, route, originAirportsQuery.data, destinationAirportsQuery.data])

  useEffect(() => {
    if (route && filters.from) {
      const codes = getCodeFromRoute(route)

      if (codes && destinationAirportsQuery.data?.length > 0) {
        const to = destinationAirportsQuery.data.find((airport) => {
          if (codes.destination.toLowerCase() !== ANY.toLowerCase()) {
            return airport.code.toLowerCase() === codes.destination
          } else {
            const airportWordsArr = airport.city
              .toLowerCase()
              .split(/\s+|[-/'’]+/)
              .filter((word) => /^[a-zA-Z]+$/.test(word))

            const airportValidation = airportWordsArr
              .filter((word) => {
                const isAlphabetic = /^[a-zA-Z]+$/.test(word)
                return isAlphabetic && !["", " ", "-"].includes(word)
              })
              .join("-")

            return (
              airportValidation === codes.destination_city.toLowerCase() &&
              airport.type === CITY
            )
          }
        })

        if (to) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            to: {
              value: to.code,
              label: `${to.city} (${to.type === CITY ? ANY : to.code})`,
              fullLabel:
                to.type === CITY
                  ? `${to.name.replace("(All)", `(${to.code})`)}`
                  : `${to.name} (${to.code})`,
              city: to.city,
              type: to.type,
              name: to.name,
            },
          }))
        }
      }
    }
  }, [
    route,
    filters.from,
    originAirportsQuery.data,
    destinationAirportsQuery.data,
  ])

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window?.innerWidth > 991)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [isDesktop])

  useEffect(() => {
    if (filters.isOneWay) {
      setFilters((existingFilters) => ({
        ...existingFilters,
        is_reversed: false,
      }))
    }
  }, [filters.isOneWay, setFilters])

  const applyFilters = useCallback(
    (newFilters) => {
      let updateFrom = newFilters.from || filters.from
      let updateTo = newFilters.to || filters.to

      const isUpdatedSearch =
        newFilters.from !== filters.from ||
        newFilters.to !== filters.to ||
        newFilters.date !== filters.date

      if (
        !dateModified &&
        newFilters.start_date &&
        newFilters.start_date !== filters.start_date
      ) {
        setDateModified(true)
      }

      if (isUpdatedSearch) {
        const currentParams = new URLSearchParams(searchParams)
        currentParams.delete("page")
        setSearchParams(currentParams)

        if (updateFrom && updateTo) {
          const { fromURL, toURL } = handleFlightSelection({
            from: { value: updateFrom.value, fullLabel: updateFrom.fullLabel },
            to: { value: updateTo.value, fullLabel: updateTo.fullLabel },
          })

          navigate(`/reward-flights/${fromURL}-to-${toURL}`)
        }
      }

      setFilters((existingFilters) => ({
        ...existingFilters,
        ...newFilters,
        to: updateTo,
      }))
      setFinishedFlightUpdate(false)
      setUpdateFlightsError(false)
    },
    [
      dateModified,
      filters.date,
      filters.from,
      filters.start_date,
      filters.to,
      navigate,
      searchParams,
      setSearchParams,
    ]
  )

  const handleChartFilterChange = ({ name, value }) => {
    setChartFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }))
  }

  const handleReverseFlights = () => {
    const { fromURL, toURL } = handleFlightSelection({
      from: { value: filters.to.value, fullLabel: filters.to.fullLabel },
      to: { value: filters.from.value, fullLabel: filters.from.fullLabel },
    })

    navigate(`/reward-flights/${fromURL}-to-${toURL}`)

    setFilters((prevFilters) => ({
      ...prevFilters,
      from: filters.to,
      to: filters.from,
      start_date: new Date(),
      end_date: new Date(),
      is_reversed: false,
      passengerCount: 1,
    }))
  }

  const isLoaded = filters.from && filters.to
  const loading = isFetching === null ? true : isFetching
  const isSearchHeaderShow = !loading && !(filters.from && filters.to)

  return (
    <>
      {mainPageSeoTags({ flightsTexts, route, isRequested })}

      <FlightDataContext.Provider
        value={{
          flights,
          isFetching: loading,
          user,
        }}
      >
        <AllAvailabilityLayout isLoading={(!isLoaded && route) || loading}>
          <Head
            isFetching={isFetching}
            isRequested={isRequested}
            flightsTexts={flightsTexts}
          />

          <Flex
            flexDirection={"column"}
            gap={4}
            mb={7}
            borderRadius={{ base: 0, lg: 12 }}
          >
            <FilterPanel
              onChange={applyFilters}
              {...filters}
              user={user}
              originAirportsQuery={originAirportsQuery}
              destinationAirportsQuery={destinationAirportsQuery}
              isFreePlan={isFreePlan}
              finishedFlightUpdate={finishedFlightUpdate}
              handleReverseFlights={handleReverseFlights}
              isFetchingUpdatedFlightData={isFetchingUpdatedFlightData}
              flightsData={flightsData}
              dateModified={dateModified}
            />
            <Outlet />
          </Flex>

          {isSearchHeaderShow && <GyozaFlightSearchHeader />}

          {isDesktop && flightsTexts && (
            <RemainingSeatsChart
              parentWidth={parentWidth}
              setParentWidth={setParentWidth}
              chartData={chartData}
              isFetchingChartData={isFetchingChartData}
              chartFilters={chartFilters}
              screenWidth={isDesktop}
              handleChartFilterChange={handleChartFilterChange}
            />
          )}

          {flightsTexts && <FAQsBetweenFlights flightsTexts={flightsTexts} />}

          <PopularFlights
            flightsTexts={flightsTexts || defaultFlightsTexts}
            top10Airports={top10Airports || []}
            url={"reward-flights"}
            isDefault={!flightsTexts}
          />
        </AllAvailabilityLayout>
      </FlightDataContext.Provider>

      {!!user && <FacebookSupport />}
    </>
  )
}

export default AllAvailability
