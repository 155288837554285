import { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons"

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Show,
  useOutsideClick,
  Text,
  Flex,
} from "@chakra-ui/react"
import {
  ITEMS_PER_PAGE_AUSTRALIA,
  SUBSCRIPTION,
} from "../../../constants/constants"

import Pagination from "../../Pagination"
import usePagination from "../../../hooks/usePagination"
import FlightsNotFound from "../../FlightsNotFound"
import FlightRow from "../components/FlightRow"
import LoadingAnimation from "../../LoadingAnimation"

const { MONTHLY, ANNUAL, ADMIN } = SUBSCRIPTION

const FlightsTable = ({
  isFetching,
  flights,
  user,
  count,
  orderBy,
  descending,
  setOrderBy,
  setDescending,
  originRegionName,
  destinationRegionName,
}) => {
  const ref = useRef()
  const { t } = useTranslation()
  const { page, onPrev, onNext, onCurrent } = usePagination()

  const [expandedFlight, setExpandedFlight] = useState(null)

  const isMonthlyOrAnnual =
    user && [MONTHLY, ANNUAL, ADMIN].includes(user?.subscription?.toLowerCase())

  useOutsideClick({
    ref,
    handler: () => setExpandedFlight(null),
  })

  const totalPages = Math.ceil((count || 0) / ITEMS_PER_PAGE_AUSTRALIA)
  const handleOnNext = () => onNext(totalPages)

  const sortFlights = (key) => {
    let isDescending = "False"

    if (key === orderBy && descending === "False") {
      isDescending = "True"
    }
    setOrderBy(key)
    setDescending(isDescending)
  }

  if (isFetching) {
    return <LoadingAnimation />
  }

  if (!flights.length) {
    return <FlightsNotFound text={"table.empty"} />
  }

  return (
    <Flex flexDirection={"column"}>
      <Table
        width="100%"
        ref={ref}
        sx={{
          "& th, & td": {
            py: { base: 3, lg: 2 },
            px: { base: 1, lg: 2 },
          },
          "& th:first-of-type, & td:first-of-type": {
            pl: { base: 3, lg: 2 },
          },
          "& th:last-of-type, & td:last-of-type": {
            pr: { base: 2, lg: 2 },
          },
        }}
      >
        <Thead>
          <Tr
            boxShadow="0px 2px 8px rgba(20, 23, 37, 0.08)"
            fontSize={[10, 12]}
            backgroundColor={"#ffffff"}
            sx={{ p: { color: "#141725" } }}
          >
            <Th
              textTransform="none"
              w={{ base: "18%", lg: "10%" }}
              cursor={{ base: "default", lg: "pointer" }}
              onClick={() => sortFlights("departure_date")}
              whiteSpace={"nowrap"}
            >
              <Flex>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.depart")}
                </Text>
                {orderBy === "departure_date" ? (
                  descending === "True" ? (
                    <ChevronUpIcon boxSize="4" />
                  ) : (
                    <ChevronDownIcon boxSize="4" />
                  )
                ) : null}
              </Flex>
            </Th>
            <Th
              textTransform="none"
              w={{ base: "20%", lg: "10%" }}
              cursor={{ base: "default", lg: "pointer" }}
              onClick={() => sortFlights("from_airport")}
            >
              <Flex>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.from")}
                </Text>
                {orderBy === "from_airport" ? (
                  descending === "True" ? (
                    <ChevronUpIcon boxSize="4" />
                  ) : (
                    <ChevronDownIcon boxSize="4" />
                  )
                ) : null}
              </Flex>
            </Th>
            <Th
              textTransform="none"
              w={{ base: "20%", lg: "10%" }}
              cursor={{ base: "default", lg: "pointer" }}
              onClick={() => sortFlights("to_airport")}
            >
              <Flex>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.to")}
                </Text>
                {orderBy === "to_airport" ? (
                  descending === "True" ? (
                    <ChevronUpIcon boxSize="4" />
                  ) : (
                    <ChevronDownIcon boxSize="4" />
                  )
                ) : null}
              </Flex>
            </Th>
            <Show above="lg">
              <Th textTransform="none" w={{ base: "20%", xl: "15%" }}>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.itinerary")}
                </Text>
              </Th>
            </Show>
            <Show below="lg">
              <Th textTransform="none" w={{ base: "32%" }}>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.pointsRequired")}
                </Text>
              </Th>
            </Show>
            <Show above="lg">
              <Th
                textTransform="none"
                w={{ lg: "8%" }}
                cursor={{ base: "default", lg: "pointer" }}
                onClick={() => sortFlights("stopovers")}
              >
                <Flex>
                  <Text fontSize={{ base: "10px", lg: "12px" }}>
                    {t("table.stops")}
                  </Text>
                  {orderBy === "stopovers" ? (
                    descending === "True" ? (
                      <ChevronUpIcon boxSize="4" />
                    ) : (
                      <ChevronDownIcon boxSize="4" />
                    )
                  ) : null}
                </Flex>
              </Th>
              <Th
                textTransform="none"
                w={{ lg: "10%" }}
                cursor={{ base: "default", lg: "pointer" }}
                onClick={() => sortFlights("business")}
              >
                <Flex>
                  <Text fontSize={{ base: "10px", lg: "12px" }}>
                    {t("table.business")}
                  </Text>
                  {orderBy === "business" ? (
                    descending === "True" ? (
                      <ChevronUpIcon boxSize="4" />
                    ) : (
                      <ChevronDownIcon boxSize="4" />
                    )
                  ) : null}
                </Flex>
              </Th>
              <Th
                textTransform="none"
                w={{ lg: "10%" }}
                cursor={{ base: "default", lg: "pointer" }}
                onClick={() => sortFlights("first")}
              >
                <Flex>
                  <Text fontSize={{ base: "10px", lg: "12px" }}>
                    {t("table.first")}
                  </Text>
                  {orderBy === "first" ? (
                    descending === "True" ? (
                      <ChevronUpIcon boxSize="4" />
                    ) : (
                      <ChevronDownIcon boxSize="4" />
                    )
                  ) : null}
                </Flex>
              </Th>
              <Th textTransform="none" w={{ lg: "10%" }}>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.lastSeen")}
                </Text>
              </Th>
            </Show>
            <Show above={"lg"}>
              <Th textTransform="none" textAlign={"center"}>
                <Text fontSize={{ base: "10px", lg: "12px" }}>
                  {t("table.bookHeader")}
                </Text>
              </Th>
            </Show>
            <Th textTransform="none" textAlign="center">
              <Text fontSize={{ base: "10px", lg: "12px" }}>
                {t("table.alert")}
              </Text>
            </Th>
            <Show below="lg">
              <Th
                textTransform="none"
                textAlign="center"
                w={{ base: "10%" }}
              ></Th>
            </Show>
          </Tr>
        </Thead>
        <Tbody>
          {flights.map((flight) => (
            <FlightRow
              key={flight.id}
              flight={flight}
              user={user}
              expandedFlight={expandedFlight}
              setExpandedFlight={setExpandedFlight}
              isMonthlyOrAnnual={isMonthlyOrAnnual}
              originRegionName={originRegionName}
              destinationRegionName={destinationRegionName}
            />
          ))}
        </Tbody>
      </Table>

      <Pagination
        page={page}
        onCurrent={onCurrent}
        onNext={handleOnNext}
        onPrev={onPrev}
        totalPages={totalPages}
      />
    </Flex>
  )
}

export default FlightsTable
