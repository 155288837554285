import { Flex } from "@chakra-ui/react"
import PricingPlans from "../components/PricingPlans"
import Footer from "../components/Footer"
import { COLORS } from "../constants/constants"
import FacebookSupport from "../components/FacebookSupport"
import { useAuthContext } from "../services/auth"

export const Pricing = () => {
  const { user } = useAuthContext()

  return (
    <Flex
      bg={COLORS.grey}
      flexGrow={1}
      minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <PricingPlans />

      <Footer />

      {!!user && <FacebookSupport />}
    </Flex>
  )
}

export default Pricing
