import { useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import {
  IconButton,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  useDisclosure,
  Image,
  Text,
} from "@chakra-ui/react"

import { COLORS } from "../constants/constants"
import helpIcon from "../img/noun-plane-trip.svg"
import closeIcon from "../img/window-close.svg"

const FacebookSupport = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { onOpen, onClose, isOpen } = useDisclosure()

  const handleClosePopover = (e) => {
    queryClient.removeQueries(["userProposal"])
    onClose(e)
  }

  return (
    <Box
      textAlign={"left"}
      position="fixed"
      bottom="20px"
      right="20px"
      zIndex={12}
    >
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleClosePopover}
        placement="top-end"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          {isOpen ? (
            <IconButton
              icon={<Image src={closeIcon} />}
              borderRadius="full"
              aria-label="Help"
              w={"64px"}
              h={"64px"}
              bg={COLORS.red}
            />
          ) : (
            <IconButton
              icon={<Image src={helpIcon} />}
              borderRadius="full"
              aria-label="Help"
              w={"64px"}
              h={"64px"}
              bg={COLORS.red}
            />
          )}
        </PopoverTrigger>

        <PopoverContent
          width={{ base: "375px", sm: "400px" }}
          borderRadius="12px"
        >
          <PopoverArrow />
          <PopoverCloseButton size={"md"} top={"26px"} right={"12px"} />
          <PopoverHeader
            fontSize={"16px"}
            fontWeight={700}
            p={"24px 24px 0"}
            mb={4}
            border={"none"}
          >
            {t("help.contactUsTitle")}
          </PopoverHeader>
          <PopoverBody p={"0 24px 24px"}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              mt={4}
              gap={2}
            >
              <Text fontSize={"14px"}>{t("help.contactUsDescription")}</Text>
              <Button
                as="a"
                href="https://m.me/gyozaflights?ref=general_contact"
                target="_blank"
                rel="noopener noreferrer"
                w="full"
                bg={COLORS.red}
                color={COLORS.white}
              >
                <Text fontSize={"14px"}>{t("help.generalContactBtn")}</Text>
              </Button>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default FacebookSupport
