import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { COLORS, EMAIL_REGEX } from "../constants/constants"
import { subscribeToNewsletter } from "../services/api"

const NewsletterSubscription = () => {
  const [formData, setFormData] = useState({ name: "", email: "" })
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const toast = useToast()

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })

    if (e.target.name === "email") {
      setEmailError(false)
    }
  }

  const handleSubmit = async () => {
    if (!formData.email) {
      setEmailError(true)
      toast({
        title: "Missing email",
        description: "Please fill in the email field.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      })
      return
    } else if (!EMAIL_REGEX.test(formData.email)) {
      setEmailError(true)
      toast({
        title: "Incorrect email",
        description: "Please write the correct email",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      })
      return
    }

    setLoading(true)

    try {
      await subscribeToNewsletter(formData)
      toast({
        title: "Subscribed!",
        description: "You have successfully subscribed to the newsletter.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      })
      setFormData({ name: "", email: "" })
    } catch (error) {
      toast({
        title: "Subscription failed",
        description:
          error.response?.data?.email[0] ===
          "newsletter subscriber with this email already exists."
            ? "This email is already subscribed!"
            : "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "bottom-right",
      })
    }
    setLoading(false)
  }

  return (
    <Flex
      flex={{ base: "0 1 100%", lg: "0 1 50%" }}
      flexDirection="column"
      textAlign="left"
      gap={{ base: 3, lg: 2 }}
      color={COLORS.black}
      maxWidth={{ base: "full", lg: "494px" }}
      px={{ base: 4, lg: 0 }}
    >
      <Box>
        <Heading as="h3" fontSize="16px" fontWeight={700}>
          Want the best reward deals?
        </Heading>
        <Text
          fontSize={{ base: "12px", lg: "14px" }}
          fontWeight={600}
          mt={{ base: 1, lg: 2 }}
        >
          Subscribe now and get daily email alerts of the best reward flights
          we’ve seen in the last 24 hours.
        </Text>
      </Box>

      <Flex
        flexDirection={{ base: "row", lg: "column", xl: "row" }}
        gap={3}
        flexWrap={{ base: "wrap", lg: "unset" }}
      >
        <Box flex={{ base: "0 1 48%" }}>
          <Input
            name="name"
            border="1px solid #D9D9D9"
            placeholder="Enter your name"
            focusBorderColor={COLORS.black}
            _focus={{ boxShadow: "none" }}
            px={3}
            fontSize="14px"
            maxWidth={{ base: "full", xl: 180 }}
            value={formData.name}
            onChange={handleChange}
          />
        </Box>

        <Box flex={{ base: "0 1 48%" }}>
          <Input
            name="email"
            type="email"
            border={emailError ? "1px solid red" : "1px solid #D9D9D9"}
            placeholder="Enter your email"
            focusBorderColor={emailError ? "red" : COLORS.black}
            _focus={{ boxShadow: "none" }}
            px={3}
            fontSize="14px"
            maxWidth={{ base: "full", xl: 180 }}
            value={formData.email}
            onChange={handleChange}
          />
        </Box>

        <Box width={{ base: "full", xl: "fit-content" }} minWidth="111px">
          <Button
            bg={COLORS.red}
            color={COLORS.white}
            textTransform="uppercase"
            px={4}
            width="full"
            isLoading={loading}
            onClick={handleSubmit}
          >
            SUBSCRIBE
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

export default NewsletterSubscription
