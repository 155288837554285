import { Button, Image } from "@chakra-ui/react"
import React from "react"
import {
  generateDynamicLink,
  getParamsForSource,
  renderBookingDetails,
} from "../../../helpers/functions"
import { useTranslation } from "react-i18next"
import { handleTrackAirlineButton } from "../../../services/analytics"

const BookingButton = ({ flight, highestPoint }) => {
  const { t } = useTranslation()

  const { airlineAwardImage, airlineAwardImageAlt, airline } =
    renderBookingDetails(flight, highestPoint, t)

  const { queryParams, rawParams } = getParamsForSource({
    flight,
    highestPoint,
  })

  const baseUrl = t(`links.${flight.source}`)
  const dynamicLink = generateDynamicLink({
    source: flight.source,
    baseUrl,
    queryParams,
    rawParams,
  })

  const handleButtonClick = () => {
    handleTrackAirlineButton({ name: airline })
  }

  return (
    <Button
      as="a"
      href={dynamicLink}
      target="_blank"
      backgroundColor={"transparent"}
      onClick={handleButtonClick}
      sx={{ _hover: { backgroundColor: "transparent" } }}
    >
      <Image
        src={airlineAwardImage}
        margin="0 auto"
        width="28px"
        height="28px"
        alt={airlineAwardImageAlt}
      />
    </Button>
  )
}

export default BookingButton
