import { useTranslation } from "react-i18next"
import { Button, Flex, Image, Box, Text } from "@chakra-ui/react"

import { handleTrackAirlineButton } from "../services/analytics"
import { COLORS, flightClassesMappingToCode } from "../constants/constants"
import qantasFFImage from "../img/qantas_logo.png"

const numberFormat = new Intl.NumberFormat()

const formatDateForURL = (isoDateString) => {
  const [year, month, day] = isoDateString.split("T")[0].split("-")
  return `${year}-${month}-${day}`
}

const generateDynamicLink = (baseUrl, queryParams) => {
  const url = new URL(baseUrl)
  Object.keys(queryParams).forEach((key) => {
    url.searchParams.append(key, queryParams[key])
  })
  return url.toString()
}

const QantasBookContent = ({ flight, highestPoint }) => {
  const { t } = useTranslation()

  const howToBookQantasLink = t("links.QF")

  const params = {
    departureAirportCode: flight.origin.code,
    arrivalAirportCode: flight.destination.code,
    departureDate: formatDateForURL(flight.flight_start_date),
    usePoints: true,
    int_cam: "n:en:cta:search+rewards+flight:classic+flight",
    tripType: "O",
    flexibleWithDates: true,
    travelClass: flightClassesMappingToCode[highestPoint.name],
    adults: 1,
  }

  const dynamicQantasLink = generateDynamicLink(howToBookQantasLink, params)

  const handleButtonClick = () => {
    handleTrackAirlineButton({ name: "Qantas Airlines" })
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justify={"space-between"} gap={"42px"} alignItems={"center"}>
        <Box>
          <Image src={qantasFFImage} height={"42px"} alt="qantas_logo" />
        </Box>
        <Box>
          <Text fontSize={"md"} fontWeight={"bold"} whiteSpace={"nowrap"}>
            {numberFormat.format(highestPoint.points)} {t("table.bookPoints")}
          </Text>
          <Text align={"right"} fontWeight={"bold"}>
            {t("table.bookTaxes")}
          </Text>
        </Box>
      </Flex>
      <Button
        as="a"
        href={dynamicQantasLink}
        target="_blank"
        w={"100%"}
        height={"36px"}
        backgroundColor={COLORS.red}
        color="white"
        borderRadius={8}
        boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
        onClick={handleButtonClick}
      >
        {t("table.bookButton")}
      </Button>
    </Flex>
  )
}

export default QantasBookContent
