import { useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { CheckIcon } from "@chakra-ui/icons"
import {
  Box,
  Heading,
  Text,
  Button,
  Flex,
  Divider,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react"
import {
  getPricingPlans,
  createCheckoutSession,
  cancelSubscription,
  sendFeedback,
} from "../services/api"
import { format, parseISO } from "date-fns"
import SubscriptionPopup from "../components/SubscriptionPopup"
import FeedbackPopup from "../components/FeedbackPopup"
import {
  COLORS,
  SUBSCRIPTION,
  MAX_ALERTS_PER_SUBSCRIPTION,
} from "../constants/constants"
import { useAuthContext } from "../services/auth"

import { ReactComponent as ActivePlanImage } from "../img/active_plan_banner.svg"

const { FREE, MONTHLY_LITE, DAY_PASS, MONTHLY, ANNUAL } = SUBSCRIPTION

const PricingPlans = ({ settingsPage = false }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { user } = useAuthContext()

  const [plan, setPlan] = useState()
  const [isProcessing, setIsProcessing] = useState(false)
  const [isCancelPopupOpen, setIsCancelPopupOpen] = useState(false)
  const [isSwitchPopupOpen, setIsSwitchPopupOpen] = useState(false)
  const [isFeedbackPopupOpen, setIsFeedbackPopupOpen] = useState(false)

  const { data: pricingPlans } = useQuery({
    queryKey: ["pricingPlans"],
    queryFn: getPricingPlans,
    initialData: [],
  })
  const { mutateAsync: checkoutSessionMutation } = useMutation({
    mutationFn: createCheckoutSession,
  })

  const { mutateAsync: sendFeedbackMutation } = useMutation({
    mutationFn: sendFeedback,
  })

  const { mutateAsync: cancelSubscriptionMutation } = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(["me"])
        setIsFeedbackPopupOpen(false)
        setIsProcessing(false)
        navigate("/")
      }, 1000)
    },
  })

  const dayPassPlan = pricingPlans?.results?.find(
    (plan) => plan.nickname.toLowerCase() === DAY_PASS
  )
  const monthlyLitePlan = pricingPlans?.results?.find(
    (plan) => plan.nickname.toLowerCase() === MONTHLY_LITE
  )
  const monthPlan = pricingPlans?.results?.find(
    (plan) => plan.nickname.toLowerCase() === MONTHLY
  )
  const annualPlan = pricingPlans?.results?.find(
    (plan) => plan.nickname.toLowerCase() === ANNUAL
  )

  const handleCancelClick = () => {
    setIsCancelPopupOpen(true)
  }

  const handleCancelClose = () => {
    setIsCancelPopupOpen(false)
  }

  const handleConfirmCancellation = () => {
    setIsFeedbackPopupOpen(true)
    setIsCancelPopupOpen(false)
  }

  const handleCancelConfirm = async ({ formData }) => {
    if (user) {
      try {
        setIsProcessing(true)

        await sendFeedbackMutation({
          queryKey: ["cancelSubscriptionFeedback", user.id, formData],
        })

        await cancelSubscriptionMutation({
          queryKey: ["cancelSubscription", user.id],
        })
      } catch (error) {
        console.error("Error canceling subscription:", error)
        setIsProcessing(false)
      }
    }
  }

  const handleSubscribeClick = (plan) => {
    setPlan(plan)
    setIsSwitchPopupOpen(true)
  }

  const handleSwitchCancel = () => {
    setIsSwitchPopupOpen(false)
  }

  const handleSubscribeConfirm = async (plan) => {
    if (plan) {
      const response = await checkoutSessionMutation({
        pricing_plan: plan,
      })
      window.location.href = response.session_url || "/"
    }

    setIsSwitchPopupOpen(false)
  }

  return (
    <Box>
      <Heading
        as="h1"
        pb={6}
        color={COLORS.black}
        textAlign="left"
        fontSize={{ base: "xl", lg: "2xl" }}
        px={{ base: 4, lg: 0 }}
      >
        {user
          ? settingsPage
            ? t("pricing.subscription")
            : t("pricing.user")
          : t("pricing.anonymousHeader")}
      </Heading>

      <Flex flexDirection={{ base: "column", xl: "row" }} gap={4} mb={7}>
        <Flex
          position="relative"
          border={
            user &&
            (user.subscription.toLowerCase() === FREE ||
              user.subscription === null)
              ? `1px solid ${COLORS.green}`
              : "1px solid transparent"
          }
          flexBasis={0}
          flexGrow={1}
          backgroundColor="white"
          borderRadius={12}
        >
          {user &&
            (user.subscription.toLowerCase() === FREE ||
              user.subscription === null) && (
              <Flex position="absolute" left={"-4px"} top={"-4px"}>
                <ActivePlanImage />
                <Text
                  position={"absolute"}
                  transform={"rotate(-45deg)"}
                  fontSize={"10px"}
                  fontWeight={800}
                  color={COLORS.white}
                  top={"26px"}
                  left={"-4px"}
                  letterSpacing={"0.75px"}
                  textTransform={"uppercase"}
                >
                  {t("pricing.activePlan")}
                </Text>
              </Flex>
            )}
          <Flex
            direction="column"
            p={5}
            justifyContent="space-between"
            width={"100%"}
          >
            <Box>
              <Text
                fontSize="sm"
                textTransform="uppercase"
                fontWeight="bold"
                color="#DD0000"
                mt={user && user.cancel_at_period_end ? "18px" : 0}
              >
                {t("pricing.free.header")}
              </Text>
              <Text fontSize={"3xl"} fontWeight={600}>
                $0.00
              </Text>

              <Divider color="rgba(33, 51, 63, 0.15)" my={5} />

              <List
                fontWeight="semibold"
                fontSize="sm"
                textAlign="left"
                mb={8}
                display={"flex"}
                flexDirection={"column"}
                gap={3}
              >
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.free.point1")}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.free.point2")}
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Button
              as={RouterLink}
              to="/"
              w={"100%"}
              height={"44px"}
              backgroundColor={COLORS.red}
              textTransform="uppercase"
              color={COLORS.white}
            >
              <Text fontSize={"14px"} fontWeight={700} lineHeight={"18px"}>
                {t("pricing.free.button")}
              </Text>
            </Button>
          </Flex>
        </Flex>

        {settingsPage &&
        user &&
        user.subscription.toLowerCase() === MONTHLY_LITE ? (
          <Flex
            position="relative"
            border={
              user && user.subscription.toLowerCase() === MONTHLY_LITE
                ? `1px solid ${COLORS.green}`
                : "1px solid transparent"
            }
            borderRadius={12}
            flexBasis={0}
            flexGrow={1}
            backgroundColor="white"
          >
            {user && user.subscription.toLowerCase() === MONTHLY_LITE && (
              <Flex position="absolute" left={"-4px"} top={"-4px"}>
                <ActivePlanImage />
                <Text
                  position={"absolute"}
                  transform={"rotate(-45deg)"}
                  fontSize={"10px"}
                  fontWeight={800}
                  color={COLORS.white}
                  top={"26px"}
                  left={"-4px"}
                  letterSpacing={"0.75px"}
                  textTransform={"uppercase"}
                >
                  {t("pricing.activePlan")}
                </Text>
              </Flex>
            )}
            <Flex
              direction="column"
              p={5}
              justifyContent="space-between"
              width={"100%"}
            >
              <Box>
                {user &&
                  user.subscription.toLowerCase() === MONTHLY_LITE &&
                  user.cancel_at_period_end && (
                    <Text colorScheme="red" fontSize="12px" fontStyle="italic">
                      ({t("login.cancels")}{" "}
                      {format(parseISO(user.current_period_end), "dd MMM yyyy")}
                      )
                    </Text>
                  )}
                <Text
                  fontSize="sm"
                  textTransform="uppercase"
                  fontWeight="bold"
                  color={COLORS.red}
                  mt={
                    user &&
                    user.subscription.toLowerCase() !== MONTHLY_LITE &&
                    user.cancel_at_period_end
                      ? "18px"
                      : 0
                  }
                >
                  {t("pricing.monthly_lite.header")}
                </Text>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                  gap={2}
                >
                  <Text position={"relative"}>
                    <Text
                      as={"span"}
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      color={COLORS.secondary}
                      position={"absolute"}
                      top={"38%"}
                      left={"-46%"}
                    >
                      AUD
                    </Text>
                    <Text as={"span"} fontWeight={600} fontSize={"3xl"}>
                      ${monthlyLitePlan?.amount}
                    </Text>
                  </Text>
                </Box>

                <Divider color="rgba(33, 51, 63, 0.15)" my={5} />

                <List
                  fontWeight="semibold"
                  fontSize="sm"
                  textAlign="left"
                  mb={8}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={3}
                >
                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.monthly_lite.point1")}
                    </Text>
                  </ListItem>
                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.monthly_lite.point2")}
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.monthly_lite.point3")}
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.monthly_lite.point4")}
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.monthly_lite.point5")}
                    </Text>
                  </ListItem>
                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.monthly_lite.point6")}
                    </Text>
                  </ListItem>
                </List>
              </Box>
              <Flex gap={2}>
                <Button
                  w="100%"
                  mt="auto"
                  backgroundColor={COLORS.grey}
                  textTransform="uppercase"
                  onClick={handleCancelClick}
                  color={COLORS.red}
                >
                  {t("login.cancel")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <Flex
            position="relative"
            border={
              user && user.subscription.toLowerCase() === DAY_PASS
                ? `1px solid ${COLORS.green}`
                : "1px solid transparent"
            }
            borderRadius={12}
            flexBasis={0}
            flexGrow={1}
            backgroundColor="white"
          >
            {user && user.subscription.toLowerCase() === DAY_PASS && (
              <Flex position="absolute" left={"-4px"} top={"-4px"}>
                <ActivePlanImage />
                <Text
                  position={"absolute"}
                  transform={"rotate(-45deg)"}
                  fontSize={"10px"}
                  fontWeight={800}
                  color={COLORS.white}
                  top={"26px"}
                  left={"-4px"}
                  letterSpacing={"0.75px"}
                  textTransform={"uppercase"}
                >
                  {t("pricing.activePlan")}
                </Text>
              </Flex>
            )}
            <Flex
              direction="column"
              p={5}
              justifyContent="space-between"
              width={"100%"}
            >
              <Box>
                {user &&
                  user.subscription.toLowerCase() === DAY_PASS &&
                  user.cancel_at_period_end && (
                    <Text colorScheme="red" fontSize="12px" fontStyle="italic">
                      ({t("login.cancels")}{" "}
                      {format(parseISO(user.current_period_end), "dd MMM yyyy")}
                      )
                    </Text>
                  )}
                <Text
                  fontSize="sm"
                  textTransform="uppercase"
                  fontWeight="bold"
                  color={COLORS.red}
                  mt={
                    user &&
                    user.subscription.toLowerCase() !== DAY_PASS &&
                    user.cancel_at_period_end
                      ? "18px"
                      : 0
                  }
                >
                  {t("pricing.24hr_day_pass.header")}
                </Text>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"flex-end"}
                  gap={2}
                >
                  <Text position={"relative"}>
                    <Text
                      as={"span"}
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      color={COLORS.secondary}
                      position={"absolute"}
                      top={"38%"}
                      left={"-46%"}
                    >
                      AUD
                    </Text>
                    <Text as={"span"} fontWeight={600} fontSize={"3xl"}>
                      ${dayPassPlan?.amount}
                    </Text>
                  </Text>
                </Box>

                <Divider color="rgba(33, 51, 63, 0.15)" my={5} />

                <List
                  fontWeight="semibold"
                  fontSize="sm"
                  textAlign="left"
                  mb={8}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={3}
                >
                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.24hr_day_pass.point1")}
                    </Text>
                  </ListItem>
                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.24hr_day_pass.point2")}
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.24hr_day_pass.point3")}
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.24hr_day_pass.point4")}
                    </Text>
                  </ListItem>

                  <ListItem display="flex">
                    <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                    <Text fontWeight={400} lineHeight={"18px"}>
                      {t("pricing.24hr_day_pass.point5")}
                    </Text>
                  </ListItem>
                </List>
              </Box>
              <Flex gap={2}>
                {!user && (
                  <Button
                    w="100%"
                    mt="auto"
                    backgroundColor={COLORS.red}
                    textTransform="uppercase"
                    color="white"
                    onClick={() => {
                      navigate(`/register?pricing_plan=${DAY_PASS}`)
                    }}
                  >
                    {t("login.signUp")}
                  </Button>
                )}

                {user &&
                  user.subscription.toLowerCase() !== DAY_PASS &&
                  !user.cancel_at_period_end && (
                    <Button
                      w="100%"
                      mt="auto"
                      backgroundColor={COLORS.red}
                      textTransform="uppercase"
                      onClick={() => handleSubscribeClick(DAY_PASS)}
                      color="white"
                    >
                      {t("login.switch")}
                    </Button>
                  )}
              </Flex>
            </Flex>
          </Flex>
        )}

        <Flex
          position={"relative"}
          border={
            user && user.subscription.toLowerCase() === MONTHLY
              ? `1px solid ${COLORS.green}`
              : "1px solid transparent"
          }
          borderRadius={12}
          flexBasis={0}
          flexGrow={1}
          backgroundColor="white"
          boxShadow={
            user && user.subscription.toLowerCase() === MONTHLY
              ? "0px 1px 2px 0px rgba(0, 0, 0, 0.1)"
              : "unset"
          }
        >
          {user && user.subscription.toLowerCase() === MONTHLY && (
            <Flex position="absolute" left={"-4px"} top={"-4px"}>
              <ActivePlanImage />
              <Text
                position={"absolute"}
                transform={"rotate(-45deg)"}
                fontSize={"10px"}
                fontWeight={800}
                color={COLORS.white}
                top={"26px"}
                left={"-4px"}
                letterSpacing={"0.75px"}
                textTransform={"uppercase"}
              >
                {t("pricing.activePlan")}
              </Text>
            </Flex>
          )}

          <Flex
            direction="column"
            justifyContent="space-between"
            p={5}
            width={"100%"}
          >
            <Box>
              {user &&
                user.subscription.toLowerCase() === MONTHLY &&
                user.cancel_at_period_end && (
                  <Text colorScheme="red" fontSize="12px" fontStyle="italic">
                    ({t("login.cancels")}{" "}
                    {format(parseISO(user.current_period_end), "dd MMM yyyy")})
                  </Text>
                )}
              <Text
                fontSize="sm"
                textTransform="uppercase"
                fontWeight="bold"
                color={COLORS.red}
                mt={
                  user &&
                  user.subscription.toLowerCase() !== MONTHLY &&
                  user.cancel_at_period_end
                    ? "18px"
                    : 0
                }
              >
                {t("pricing.monthly_max.header")}
              </Text>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
                gap={2}
              >
                <Text position={"relative"}>
                  <Text
                    as={"span"}
                    fontSize={"sm"}
                    fontWeight={"bold"}
                    color={COLORS.secondary}
                    position={"absolute"}
                    top={"38%"}
                    left={"-38%"}
                  >
                    AUD
                  </Text>
                  <Text as={"span"} fontWeight={600} fontSize={"3xl"}>
                    ${monthPlan?.amount}
                  </Text>
                </Text>
              </Box>

              <Divider color="rgba(33, 51, 63, 0.15)" my={5} />

              <List
                fontWeight="semibold"
                fontSize="sm"
                textAlign="left"
                mb={8}
                display={"flex"}
                flexDirection={"column"}
                gap={3}
              >
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point1")}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point2", {
                      alertsCount: MAX_ALERTS_PER_SUBSCRIPTION.MONTHLY,
                    })}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point3")}
                  </Text>
                </ListItem>

                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point4")}
                  </Text>
                </ListItem>

                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point5")}
                  </Text>
                </ListItem>

                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point6")}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.monthly_max.point7")}
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Flex gap={2}>
              {!user && (
                <Button
                  w="100%"
                  mt="auto"
                  backgroundColor={COLORS.red}
                  textTransform="uppercase"
                  color="white"
                  onClick={() => {
                    navigate(`/register?pricing_plan=${MONTHLY}`)
                  }}
                >
                  {t("login.signUp")}
                </Button>
              )}

              {user && user.cancel_at_period_end && (
                <Button
                  w="100%"
                  mt="auto"
                  backgroundColor={COLORS.red}
                  textTransform={"uppercase"}
                  color="white"
                  onClick={() => handleSubscribeClick(MONTHLY)}
                >
                  {t("login.resubscribe")}
                </Button>
              )}

              {user &&
                user.subscription.toLowerCase() === MONTHLY &&
                !user.cancel_at_period_end && (
                  <Button
                    w="100%"
                    mt="auto"
                    backgroundColor={COLORS.grey}
                    textTransform="uppercase"
                    onClick={handleCancelClick}
                    color={COLORS.red}
                  >
                    {t("login.cancel")}
                  </Button>
                )}

              {user &&
                user.subscription.toLowerCase() !== MONTHLY &&
                !user.cancel_at_period_end && (
                  <Button
                    w="100%"
                    mt="auto"
                    backgroundColor={COLORS.red}
                    textTransform="uppercase"
                    onClick={() => handleSubscribeClick(MONTHLY)}
                    color="white"
                  >
                    {t("login.switch")}
                  </Button>
                )}
            </Flex>
          </Flex>
        </Flex>

        <Flex
          position={"relative"}
          border={
            user && user.subscription.toLowerCase() === ANNUAL
              ? `1px solid ${COLORS.green}`
              : "1px solid transparent"
          }
          borderRadius={12}
          flexBasis={0}
          flexGrow={1}
          backgroundColor="white"
          boxShadow={
            user && user.subscription.toLowerCase() === ANNUAL
              ? "0px 1px 2px 0px rgba(0, 0, 0, 0.1)"
              : "unset"
          }
        >
          {user && user.subscription.toLowerCase() === ANNUAL && (
            <Flex position="absolute" left={"-4px"} top={"-4px"}>
              <ActivePlanImage />
              <Text
                position={"absolute"}
                transform={"rotate(-45deg)"}
                fontSize={"10px"}
                fontWeight={800}
                color={COLORS.white}
                top={"26px"}
                left={"-4px"}
                letterSpacing={"0.75px"}
                textTransform={"uppercase"}
              >
                {t("pricing.activePlan")}
              </Text>
            </Flex>
          )}
          <Flex
            direction="column"
            justifyContent="space-between"
            p={5}
            width={"100%"}
          >
            <Box>
              {user &&
                user.subscription.toLowerCase() === ANNUAL &&
                user.cancel_at_period_end && (
                  <Text colorScheme="red" fontSize="12px" fontStyle="italic">
                    ({t("login.cancels")}{" "}
                    {format(parseISO(user.current_period_end), "dd MMM yyyy")})
                  </Text>
                )}

              <Text
                fontSize="sm"
                textTransform="uppercase"
                fontWeight="bold"
                color={COLORS.red}
                mt={
                  user &&
                  user.subscription.toLowerCase() !== ANNUAL &&
                  user.cancel_at_period_end
                    ? "18px"
                    : 0
                }
              >
                {t("pricing.annual.header")}
              </Text>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"flex-end"}
                gap={2}
              >
                <Text position={"relative"}>
                  <Text
                    as={"span"}
                    fontSize={"sm"}
                    fontWeight={"bold"}
                    color={COLORS.secondary}
                    position={"absolute"}
                    top={"38%"}
                    left={"-32%"}
                  >
                    AUD
                  </Text>
                  <Text as={"span"} fontWeight={600} fontSize={"3xl"}>
                    ${annualPlan?.amount}
                  </Text>
                </Text>
              </Box>

              <Divider color="rgba(33, 51, 63, 0.15)" my={5} />

              <List
                fontWeight="semibold"
                fontSize="sm"
                textAlign="left"
                mb={8}
                display={"flex"}
                flexDirection={"column"}
                gap={3}
              >
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point1")}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point2", {
                      alertsCount: MAX_ALERTS_PER_SUBSCRIPTION.ANNUAL,
                    })}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point3")}
                  </Text>
                </ListItem>

                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point4")}
                  </Text>
                </ListItem>

                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point5")}
                  </Text>
                </ListItem>

                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point6")}
                  </Text>
                </ListItem>
                <ListItem display="flex">
                  <ListIcon as={CheckIcon} color={COLORS.green} mt={"2px"} />
                  <Text fontWeight={400} lineHeight={"18px"}>
                    {t("pricing.annual.point7")}
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Flex gap={2}>
              {!user && (
                <Button
                  w="100%"
                  mt="auto"
                  backgroundColor={COLORS.red}
                  textTransform={"uppercase"}
                  color="white"
                  onClick={() => {
                    navigate(`/register?pricing_plan=${ANNUAL}`)
                  }}
                >
                  {t("login.signUp")}
                </Button>
              )}

              {user && user.cancel_at_period_end && (
                <Button
                  w="100%"
                  mt="auto"
                  backgroundColor={COLORS.red}
                  textTransform={"uppercase"}
                  onClick={() => handleSubscribeClick(ANNUAL)}
                  color="white"
                >
                  {t("login.resubscribe")}
                </Button>
              )}

              {user &&
                user.subscription.toLowerCase() === ANNUAL &&
                !user.cancel_at_period_end && (
                  <Button
                    w="100%"
                    mt="auto"
                    backgroundColor={COLORS.grey}
                    textTransform="uppercase"
                    onClick={handleCancelClick}
                    color={COLORS.red}
                  >
                    {t("login.cancel")}
                  </Button>
                )}

              {user &&
                user.subscription.toLowerCase() !== ANNUAL &&
                !user.cancel_at_period_end && (
                  <Button
                    w="100%"
                    mt="auto"
                    backgroundColor={COLORS.red}
                    textTransform="uppercase"
                    onClick={() => handleSubscribeClick(ANNUAL)}
                    color="white"
                  >
                    {t("login.switch")}
                  </Button>
                )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <SubscriptionPopup
        isOpen={isCancelPopupOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmCancellation}
        header="Cancel Subscription"
        body={t("subscription.cancellation.text")}
        type="cancel"
      />
      <SubscriptionPopup
        isOpen={isSwitchPopupOpen}
        onClose={handleSwitchCancel}
        onConfirm={handleSubscribeConfirm}
        header="Switch Subscription"
        body={t("subscription.switch.text")}
        type="switch"
        plan={plan}
      />

      {isFeedbackPopupOpen && (
        <FeedbackPopup
          isOpen={isFeedbackPopupOpen}
          onConfirm={handleCancelConfirm}
          onClose={() => setIsFeedbackPopupOpen(false)}
          isProcessing={isProcessing}
        />
      )}
    </Box>
  )
}

export default PricingPlans
