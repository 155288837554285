import { useEffect } from "react"

const useOutsideAlerter = (ref, onOutsideClick) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick()
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onOutsideClick])
}

export default useOutsideAlerter
